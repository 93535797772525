import './RichText.css'
import { RichTextEditor } from "@mantine/tiptap";
import { IconColorPicker } from "@tabler/icons-react";
import { useEffect } from 'react';

export default function RichText({
   editor, 
   editable = true, 
   minHeight = 'max-content', 
   classNames = {}, 
   border = '1px solid #ced4da',
   bgContent = '#fff',
   changeValue
}) {
   const styles = {
      border
   };

   useEffect(() => {
      if (editor) {
         editor.on('update', () => {
            const html = editor.getHTML();
            changeValue(html);
         });
      }
   }, [editor, changeValue]);

   return (
      <RichTextEditor editor={editor} style={styles} classNames={classNames}>
         {editable &&  <RichTextEditor.Toolbar >
            <RichTextEditor.ControlsGroup>
               <RichTextEditor.Bold />
               <RichTextEditor.Italic />
               <RichTextEditor.Underline />
               <RichTextEditor.Strikethrough />
               <RichTextEditor.ClearFormatting />
               <RichTextEditor.Highlight />
               <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.H1 />
               <RichTextEditor.H2 />
               <RichTextEditor.H3 />
               <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.Blockquote />
               <RichTextEditor.Hr />
               <RichTextEditor.BulletList />
               <RichTextEditor.OrderedList />
               <RichTextEditor.Subscript />
               <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.Link />
               <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.AlignLeft />
               <RichTextEditor.AlignCenter />
               <RichTextEditor.AlignJustify />
               <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.Undo />
               <RichTextEditor.Redo />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
               <RichTextEditor.ColorPicker
                  colors={[
                     "#25262b",
                     "#868e96",
                     "#fa5252",
                     "#e64980",
                     "#be4bdb",
                     "#7950f2",
                     "#4c6ef5",
                     "#228be6",
                     "#15aabf",
                     "#12b886",
                     "#40c057",
                     "#82c91e",
                     "#fab005",
                     "#fd7e14",
                  ]}
               />
               <RichTextEditor.Control interactive={false}>
                  <IconColorPicker size="1rem" stroke={1.5} />
               </RichTextEditor.Control>

               <RichTextEditor.Color color="#F03E3E" />
               <RichTextEditor.Color color="#7048E8" />
               <RichTextEditor.Color color="#1098AD" />
               <RichTextEditor.Color color="#37B24D" />
               <RichTextEditor.Color color="#F59F00" />
            </RichTextEditor.ControlsGroup>


            <RichTextEditor.UnsetColor />
         </RichTextEditor.Toolbar>}

         <RichTextEditor.Content mih={minHeight} bg={bgContent} />

      </RichTextEditor>
   )
}
