import React from 'react';
import './QuestionIndicator.css';
import {IconHelpOctagon} from "@tabler/icons-react";

function QuestionIndicator(props) {
    const {currentQuestion, amountOfQuestions} = props;
    return (
        <div className="questionIndicator__container">
            <IconHelpOctagon size={24} className="questionIndicator__icon"/>
            <p className="questionIndicator__text--bold">Pregunta:</p>
            <p className="questionIndicator__text">{`${currentQuestion} / ${amountOfQuestions}`}</p>
        </div>
    );
}

export default QuestionIndicator;