import { Skeleton } from '@mantine/core'
import './CourseCardSkeleton.css'

export default function CourseCardSkeleton({ withTitle = false }) {
   return (
      <div className='CourseCardSkeleton-container'>
         {withTitle && < Skeleton h={24} width={'200px'} />}
         <div className='CourseCardSkeleton-grid-container'>
            <article className='CourseCard-skeleton-card-container'>

               <div className='CourseCard-skeleton--row'>
                  < Skeleton h='100%' w='20%' />
                  <div className='CourseCard-skeleton--text-container'>
                     <div className='CourseCard-skeleton--title'>
                        < Skeleton h={16} w='80%' />
                        < Skeleton h={16} w='60%' />
                     </div>

                     <div className='CourseCard-skeleton-footer'>
                        < Skeleton h={16} w='20%' />
                     </div>

                  </div>
               </div>

               <Skeleton h='30%' width='100%' />
            </article>

            <article className='CourseCard-skeleton-card-container'>

               <div className='CourseCard-skeleton--row'>
                  < Skeleton h='100%' w='20%' />
                  <div className='CourseCard-skeleton--text-container'>
                     <div className='CourseCard-skeleton--title'>
                        < Skeleton h={16} w='66%' />
                        < Skeleton h={16} w='60%' />
                     </div>

                     <div className='CourseCard-skeleton-footer'>
                        < Skeleton h={16} w='20%' />
                     </div>

                  </div>
               </div>

               <Skeleton h='30%' width='100%' />
            </article>
         </div>
      </div>
   )
}
