import { isValidPhoneNumber } from "react-phone-number-input"
import { COUNTRY_LIST } from "./country-list-registerPage"
import { isValidDate } from "./validateDate"
import { GENDER_LIST } from "./gender-list"
import { capitalize } from "./capitalize"
import { RATE_VALUES_ARR } from "./rate-values"
import { passwordStrength } from 'check-password-strength'

export const VALIDATIONS = {
   name: (value) => {
      const isValid = /^[a-zA-Z\s]+$/.test(value)
      return isValid && value.length >= 2
         ? null
         : "No debe tener al menos 2 letras y no contener puntos, guiones ni comas"
   },
   lastname: (value) => {
      const isValid = /^[a-zA-Z\s]+$/.test(value)
      return isValid && value.length >= 2
         ? null
         : "Debe tener al menos 2 letras y no contener puntos, guiones ni comas"
   },
   phone: (value) => {
      if (typeof value === "string")
         return !isValidPhoneNumber(value)
      else return true
   },
   dni: value => {
      if (!value) return "Debes completar con tu número de identificación"
      if (typeof value !== 'number') return "El número de identificación no puede contener letras ni símbolos."
   },
   nationality: (value) => COUNTRY_LIST.includes(capitalize(value)) ? null : 'El país ingresado no es correcto',
   city: (value) => {
      const isValid = /^[a-zA-Z\s]+$/.test(value)
      return isValid && value.length >= 2
         ? null
         : "No debe tener al menos 2 letras y no contener puntos, guiones ni comas"
   },
   birthDate: (value) => {
      const errorMessage = isValidDate(value);
      return errorMessage ? errorMessage : null;
   },
   description: (value) => {
      // If value is empty, it's valid
      if (!value) {
         return null;
      }

      if (value.length < 1 || value.length > 255) {
         return 'El texto debe tener entre 1 y 255 caracteres';
      }

      if (!/^[a-zA-Z0-9\s.,;:'"!?(){}[\]<>]*$/.test(value)) {
         return 'El texto contiene caracteres no permitidos';
      }

      return null; // El texto es válido
   },
   firstPassword: (value, values) => {
      // Si no hay valor en el campo de confirmación de contraseña, no se realiza ninguna validación
      if (!values.firstPassword) {
         return null;
      }

      const strength = passwordStrength(values.firstPassword);
      if (strength.id < 3) {
         return 'La contraseña ingresada no es lo suficientemente segura';
      }

      return null; // La contraseña es válida
   },
   confirmPassword: (value, values) => {
      return value !== values.firstPassword ? 'Las contraseñas no coinciden' : null
   },
   gender: (value) => GENDER_LIST.includes(value && capitalize(value)) ? null : 'El género ingresado no es correcto',
   about: (value) => {
      const isValid = /^[a-zA-Z0-9\s.,;:'"!?(){}[\]<>]*$/.test(value)
      return isValid && (value.length >= 0 && value.length <= 255)
         ? null
         : "El texto debe ser hasta 50 caractéres y no deberá tener símbolos extraños."
   },
   teachersCommit: (value) => {
      // valida tanto si es undefined y tambien si quieren inyectar algo malicioso ya que debe incluir lo que este en el array
      const isValid = RATE_VALUES_ARR.includes(value)
      return isValid ? null : 'No has completado todos los campos correctamente'
   },
   moderation: (value) => {
      // valida tanto si es undefined y tambien si quieren inyectar algo malicioso ya que debe incluir lo que este en el array
      const isValid = RATE_VALUES_ARR.includes(value)
      return isValid ? null : 'No has completado todos los campos correctamente'
   },
   forVetExperience: (value) => {
      // valida tanto si es undefined y tambien si quieren inyectar algo malicioso ya que debe incluir lo que este en el array
      const isValid = RATE_VALUES_ARR.includes(value)
      return isValid ? null : 'No has completado todos los campos correctamente'
   },
   webCampusValue: (value) => {
      // valida tanto si es undefined y tambien si quieren inyectar algo malicioso ya que debe incluir lo que este en el array
      const isValid = RATE_VALUES_ARR.includes(value)
      return isValid ? null : 'No has completado todos los campos correctamente'
   },
   extraCommit: (value) => {
      if (value === '') return null

      const isValid = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s.,;:'"(){}[\]<>?]+$/.test(value)
      return (value.length < 150 && isValid) ? null : 'El mensaje debe ser menor a 150 caracteres sin simbolos extraños.'
   },
}