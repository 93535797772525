import { IconDog, IconMoodSmile, IconSchool, IconMessageShare, IconAlertCircle, IconLock, IconBug } from "@tabler/icons-react";

export const FORUM_RULES = [
   {
      icon: < IconMoodSmile size={24} color="#2C4B58" />,
      title: 'Respeto y Cortesía:',
      text: 'Todos los usuarios deben tratar a los demás con respeto y cortesía en todas las interacciones dentro del foro. Esto incluye evitar lenguaje ofensivo, ataques personales, discriminación y cualquier forma de acoso.'
   },
   {
      icon: < IconDog size={24} color="#2C4B58" />,
      title: 'Contenido Apropiado:',
      text: 'Se debe publicar contenido relevante y apropiado para el contexto académico de FORVET. Esto incluye preguntas, respuestas, comentarios y cualquier otro tipo de contribución.'
   },
   {
      icon: < IconSchool size={24} color="#2C4B58" />,
      title: 'Integridad Académica:',
      text: 'Los usuarios deben respetar los principios de integridad académica y evitar cualquier forma de plagio o fraude académico. Todas las contribuciones deben ser originales y correctamente atribuidas cuando corresponda.'
   },
   {
      icon: < IconMessageShare size={24} color="#2C4B58" />,
      title: 'Colaboración y Compartir Conocimientos:',
      text: 'FORVET es un espacio para la colaboración y el intercambio de conocimientos. Los usuarios deben contribuir de manera constructiva y estar dispuestos a ayudar a otros miembros de la comunidad.'
   },
   {
      icon: < IconAlertCircle size={24} color="#2C4B58" />,
      title: 'Cumplimiento de las Normas:',
      text: 'Todos los usuarios deben cumplir con las normas establecidas por FORVET, así como con las leyes y regulaciones aplicables. Cualquier violación de estas normas puede resultar en acciones disciplinarias, incluida la suspensión o la expulsión de la plataforma.'
   },
   {
      icon: < IconLock size={24} color="#2C4B58" />,
      title: 'Protección de la Privacidad:',
      text: 'Se debe respetar la privacidad de los demás usuarios y abstenerse de compartir información personal sin consentimiento explícito. Esto incluye información como nombres completos, direcciones de correo electrónico, números de teléfono, entre otros.'
   },
   {
      icon: < IconBug size={24} color="#2C4B58" />,
      title: 'Reporte de Problemas:',
      text: 'Los usuarios deben informar cualquier problema o comportamiento inapropiado a los administradores de FORVET de manera oportuna para que puedan tomar las medidas necesarias.'
   }
]

export const NO_AUTHORIZATION_CODE = 401
