import "./Login.css";
import useLoginPage from "../../hooks/useLoginPage";
import LoginHeader from "../../features/Login/LoginHeader/LoginHeader";
import LoginCarousel from "../../features/Login/LoginCarousel/LoginCarousel";
import LoginAnimationImage from "../../features/Login/LoginAnimationImage/LoginAnimationImage";
import AvalCarousel from "../../components/AvalCarousel/AvalCarousel";
import { Button, Modal, Skeleton } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { openUrl } from "../../utils/OpenUrl";

export default function Login() {
  const { acaUnits, state, avals, isNotBrowserRecommended } = useLoginPage();
  const location = useLocation();
  const message =
    location.search && new URLSearchParams(location.search).get("message");
  const [opened, setOpened] = useState(message != null);

  const close = () => setOpened(false);

  const handleNavigate = () => {
   openUrl("https://forvet.org/contacto")
  };

  return (
    <main className="LoginPage-container">
      <div className="LoginPage--component">
        <div className="LoginPage-wrapper">
          <LoginHeader />

          <section className="LoginPage-heading-title-container">
            <h1 className="LoginPage-heading-title">¡Bienvenidos al Campus!</h1>
          </section>

          <div className="LoginPage-separator--line"></div>

          {isNotBrowserRecommended && (
            <section className="LoginPage-browser-warning-container">
              <IconExclamationCircle size={40} color="#2C4B58" />
              <p className="LoginPage-browser-warning--text">
                Estas usando el navegador{" "}
                <strong>{isNotBrowserRecommended}</strong>{" "}
              </p>
              <p className="LoginPage-browser-warning--text">
                Para una mejor experiencia te recomendamos que utilices{" "}
                <strong>Chrome</strong> o <strong>Edge</strong> o puedes
                habilitar las <strong>cookies de terceros</strong>
              </p>
            </section>
          )}

          {!acaUnits && (
            <div className="LoginPage-skeleton">
              <Skeleton h={300} width={"100%"} />
            </div>
          )}
          {acaUnits && (
            <section className="LoginPage-content-container">
              <LoginCarousel acaUnits={acaUnits.open} state={state} />
              <LoginAnimationImage />
            </section>
          )}
        </div>

        <footer className="LoginPage-footer-container">
          <AvalCarousel itemsToMap={avals} state={state} />
        </footer>
      </div>
      {message && (
        <Modal
          classNames={{
            title: "loginPage-modal-titleHeader",
            body: "loginPage-modal-body",
            header: "loginPage-modal-headerStyling",
          }}
          opened={opened}
          onClose={close}
          title="Hay irregularidades en tu cuenta"
        >
          <div className="loginPage-modal-layout">
            <img
              className="loginPage-modal-img"
              src="https://ugokawaii.com/wp-content/uploads/2022/06/dog-distress.gif"
            />
            {message}
            <Button onClick={handleNavigate} fw="normal">
              Contáctanos
            </Button>
          </div>
        </Modal>
      )}
    </main>
  );
}
