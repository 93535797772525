import { IconTrendingUp } from '@tabler/icons-react'
import './RateNotificationBanner.css'
import { RATE_VALUES } from '../../../../utils/rate-values'

export default function RateNotificationBanner({ message, rate }) {

   const renderStyles = () => {
      switch (rate) {
         case RATE_VALUES.good:
            return {style: 'RateClassBanner-notification--textRate RateClassBanner-notification-good', text: 'Buena'}
         case RATE_VALUES.regular:
            return {style: 'RateClassBanner-notification--textRate RateClassBanner-notification-regular', text: 'Media'}
         default:
            return {style: 'RateClassBanner-notification--textRate RateClassBanner-notification-bad', text: 'Mala'}
      }
   }

   return (
      <div className='RateClassBanner-notification-container'>
         <p className='RateClassBanner-notification-p-first'>{message}</p>


         < IconTrendingUp size={150} />
         <span className={renderStyles().style}>{renderStyles().text}</span>

         <div className='RateClassBanner-notification-p-container'>
            <p>Gracias por tu colaboración</p>
            <p>Tu opinión nos sirve para mejorar</p>
         </div>

      </div>
   )
}
