import React from 'react';
import { Select, TextInput } from '@mantine/core';
import './HelpHeader.css';
import ImageOverShape from '../../../components/ImageOverShape/ImageOverShape';
import HelpDog from '../../../assets/Help/Header-Help-Dog.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const HelpHeader = ({ onSearchChange, onThemeSearch, themes  }) => {
  const {width} = useWindowDimensions();
  const isTablet = width < 1000;
  const isMobile = width < 768;

  const handleThemeChange = (selectedTheme) => {
    onThemeSearch(selectedTheme);
  };
  return (
    <>
    <div className={`helpHeader-father-div ${isTablet ? 'helpHeader-father-div-tablet' : ''}`}>
      <div className='helpHeader-titles-div'>
        <h2 className='helpHeader-title'>Centro de Ayuda</h2>
        <h4 className='helpHeader-subtitle'>Bienvenido al centro de ayuda de nuestro campus.</h4>
      </div>
      {!isTablet && <div className='helpHeader-image'>
        <ImageOverShape left="0px" square height={160} imageUrl={HelpDog} backgroundColor='var(--color-secondary)' />
      </div>}
      
      <div className={`helpHeader-selector ${isMobile ? 'helpHeader-selector-mobile' : ''}`}>
        <TextInput
          placeholder="Buscar por palabra/s clave/s"
          onChange={(event) => onSearchChange(event.target.value)}
        />
        <Select placeholder="Filtra por tema" onChange={handleThemeChange} data={themes.map(theme => ({ label: theme, value: theme }))}/>
      </div>
    </div>
    </>
  );
};

export default HelpHeader;
