import useContactCard from '../../hooks/useContactCard';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './HelpContactCard.css';
const HelpContactCard = () => {
  const {width} = useWindowDimensions();
  const isMobile = width < 768;
  const { generateContactItems } = useContactCard(); 
  const items = generateContactItems();

  return (
    <div className="advancedQueryForm-contact-wrapper">
        <div className="advancedQueryForm-contact-contacts">
          <h3 className="advancedQueryForm-contact-title">Contacto</h3>
          <div className={`advancedQueryForm-contact-items ${isMobile ? 'advancedQueryForm-contact-items-mobile' : ''}`}>{items}</div>
        </div>
      </div>
  )
}
export default HelpContactCard