import useWindowDimensions from '../../hooks/useWindowDimensions';
import './DecorativeEmptyCircle.css'

export default function DecorativeEmptyCircle(props) {
   const { size, opacity, color = 1, top, left, right, bottom, hideInMobile, borderWidth = 12, zIndex} = props
   const {width} = useWindowDimensions();
   const isMobile = width <= 768
   
   const style = {
      width: `${size}px`,
      height: `${size}px`,
      borderColor: color,
      borderWidth: `${borderWidth}px`,
      backgroundColor: 'transparent',
      opacity: opacity,
      zIndex: zIndex,
   }

   if (top !== undefined) {
      style.top = `${top}px`;
  }

  if (left !== undefined) {
      style.left = `${left}px`;
  }

  if (right !== undefined) {
      style.right = `${right}px`;
  }

  if (bottom !== undefined) {
      style.bottom = `${bottom}px`;
  }

  if (hideInMobile !== undefined && isMobile) {
      style.display = `none`;
  }

   return (
      <div className='decorative-empty-circle-container' style={style}>
      </div>
   )
}
