import './CoursesClassSkeleton.css'
import { Skeleton } from '@mantine/core';


export default function CoursesClassSkeleton() {
   return (
      <div className='CoursesClassSkeleton-container coursesClass-nav-section'>
         < Skeleton width='100%' height='68px' />
         < Skeleton width='100%' height='186px' />
         < Skeleton width='100%' height='68px' />
         < Skeleton width='100%' height='302px' />
         <div className='ClassDetailsCards-container'>
            < Skeleton width='100%' height='266px' />
            < Skeleton width='100%' height='266px' />
            < Skeleton width='100%' height='266px' />
         </div>
      </div>
   )
}
