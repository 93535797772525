import './Forum.css'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import ForumPageHeader from '../../features/Forum/ForumPageHeader/ForumPageHeader'
import ForumPageContent from '../../features/Forum/ForumPageContent/ForumPageContent'
import useForum from '../../hooks/useForum'
import ForumTermsAndConditionsModal
   from '../../features/Forum/ForumTermsAndConditionsModal/ForumTermsAndConditionsModal'
import ForumNoAcceptTermsAndConditions
   from '../../features/Forum/ForumNoAcceptTermsAndConditions/ForumNoAcceptTermsAndConditions'
import ForumNewDiscussion from '../../components/Forum/ForumNewDiscussion/ForumNewDiscussion'
import { useContext } from 'react'
import AppContext from '../../contexts/AppContext'
import { NO_AUTHORIZATION_CODE } from '../../utils/forum-rules'

export default function Forum() {

   const { discussionThemes } = useContext(AppContext)
   const {
      isDecrecent,
      allDiscussionTopics,
      handleToggleFilter,
      state,
      getDiscussionThemes,
      isModal,
      setIsModal,
      setTopics,
      reRender, 
      setReRender,
   } = useForum()


   return (
      <MainLayout>
         <div className='ForoPage-section-wrapper'>
            {discussionThemes && discussionThemes !== undefined
               ? < ForumNewDiscussion reRender={reRender} setReRender={setReRender} />
               : <section className='ForoPage-section-container'>

                  < ForumPageHeader
                     handleToggleFilter={handleToggleFilter}
                     isDecrecent={isDecrecent}
                     getDiscussionThemes={getDiscussionThemes}
                     state={state}
                  />

                  {/* modal para los terminos y condiciones */}
                  {isModal && < ForumTermsAndConditionsModal isModal={isModal} setIsModal={setIsModal} />}

                  {/* se fija primero si tiene autorizacion, luego el componente maneja los errores dentro */}
                  {(state.error.status === NO_AUTHORIZATION_CODE && !isModal)
                     ? < ForumNoAcceptTermsAndConditions setIsModal={setIsModal} />
                     : < ForumPageContent
                        state={state}
                        data={allDiscussionTopics}
                     />
                  }
               </section>
            }
         </div>
      </MainLayout>
   )
}
