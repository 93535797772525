import "./CoursesClassHeader.css";
import moment from "moment/moment";
import { IconCopy, IconVideo } from "@tabler/icons-react";
import { Button, HoverCard } from "@mantine/core";
import { openUrl } from "../../../utils/OpenUrl";
import showNotification from "../../../utils/showNotification";

const CoursesClassHeader = ({ title, state, date, roomURL, zoomCode }) => {
  let cardContent;

  const handleCopy = () => {
    navigator.clipboard.writeText(zoomCode).then(() => {
      showNotification({
        color: "green",
        status: "success",
        title: "¡Código copiado!",
        message: `¡Código copiado con éxito! Pegalo en zoom para acceder a la clase.`,
      });
    });
  };

  switch (state) {
    case "Open":
      cardContent = (
        <div className="courseClassHeader-openClass-father-div">
          <p className="courseClassHeader-openClass-text">
            Esta clase comenzará el {moment(date).format("DD/MM/YY - HH:mm")} hs.
          </p>

          <div className="courseClassHeader-openClass-button-div">
            {zoomCode !== undefined && (
              <HoverCard width={140}>
                <HoverCard.Target>
                  <p
                    className="courseClassHeader-hoverCard-textDescription"
                    fw="normal"
                  >
                    Código zoom
                  </p>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <div className="courseClassHeader-hoverCard-father-div">
                    <p className="courseClassHeader-hoverCard-codeText">
                      {zoomCode}
                    </p>

                    <IconCopy
                      className="courseClassHeader-hoverCard-copyIconStyling"
                      onClick={handleCopy}
                    />
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            )}
            <Button
              fw="normal"
              variant="outline"
              color="var(--color-text-tertiary)"
              onClick={() => openUrl(`${roomURL}`)}
            >
              Ingresá al aula
              <span className="courseClassHeader-openClass-button-span">
                {<IconVideo />}
              </span>
            </Button>
          </div>
        </div>
      );
      break;
    case "Closed":
      cardContent = (
        <div className="courseClassHeader-closedClass-father-div">
          <p className="courseClassHeader-closedClass-text">
            Puedes volver a ver esta clase cuando quieras haciendo click aquí:
          </p>
          <div className="courseClassHeader-closedClass-button-div">
            <Button
              fw="normal"
              variant="outline"
              color="var(--color-text-primary)"
              onClick={() => openUrl(`${roomURL}`)}
            >
              Ver grabación
              <span className="courseClassHeader-closedClass-button-span">
                {<IconVideo />}
              </span>
            </Button>
          </div>
        </div>
      );
      break;
    default:
      cardContent = null;
      break;
  }

  return (
    <>
      <div className="coursesClassHeader-classTitle-div">
        <h2 className="coursesClassHeader-title-styling">{title}</h2>
      </div>
      {cardContent}
    </>
  );
};

export default CoursesClassHeader;
