import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { Link, useParams } from "react-router-dom";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import { Highlight } from "@mantine/core";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Placeholder from "@tiptap/extension-placeholder";
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import TextAlign from "@tiptap/extension-text-align";
import axios from "axios";
import showNotification from "../utils/showNotification";
import { ERROR_HANDLER } from "../utils/error-handler";


export default function useClassNotes(initialNotes) {
   const { authToken } = useContext(AuthContext);
   const { lessonId } = useParams();
   const [loading, setloading] = useState(false);
   const [loadingDownloads, setloadingDownloads] = useState(false);
   const [notes, setNotes] = useState(initialNotes);

   const content = "";

   const editor = useEditor({
      extensions: [
         StarterKit,
         Underline,
         Link,
         Superscript,
         Subscript,
         Highlight,
         TextStyle,
         Color,
         Placeholder.configure({
            placeholder: 'Añade aquí tus notas de esta clase',
         }),
         TextAlign.configure({ types: ["heading", "paragraph"] }),
         Heading.configure({
            levels: [1, 2, 3, 4],
            HTMLAttributes: {
               class: 'RichTextEditor--heading'
            }
         }),
         Paragraph.configure({
            HTMLAttributes: {
               class: 'RichTextEditor--paragraph'
            }
         })

      ],
      content,
   });

   // const cleanHtmlContent = (htmlContent) => {
   //    const cleanContent = htmlContent.replace(/<[^>]*>/g, "");
   //    return cleanContent;
   // };

   useEffect(() => {
      setNotes(initialNotes);
   }, [initialNotes]);

   /* Clears the content sent to backend. It´s necessary the useEffect to acomplish the task. */
   useEffect(() => {
      if (editor) {
         editor.commands.clearContent();
      }
   }, [notes, editor]);

   const downloadNotes = async () => {
      try {
         setloadingDownloads(true);

         const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/course/${lessonId}/pdf`,
            {
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + authToken,
               },
               responseType: "blob"
            }
         );
         const blob = new Blob([response.data], { type: "application/pdf" });
         const url = window.URL.createObjectURL(blob);
         window.open(url);
      } catch (error) {
         console.error("Error getting pdf:", error);
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al intentar descargar tus notas.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000,
        });
      } finally {
         setloadingDownloads(false);
      }
   };

   const handleSaveNotes = async () => {
      try {
         setloading(true);
         await axios.put(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/classNotes/${lessonId}`,
            { notes: editor.getHTML() },
            {
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + authToken,
               }
            }
         );
         setNotes((prevNotes) => [...prevNotes, { selfNotes: editor.getHTML() }]);
         showNotification({
            color: 'green',
            status: 'success',
            title: '¡Notas guardadas con éxito!.',
            message: `Podrás ver tus notas con la personalización deseada al descargarla como PDF.`,
         });
      } catch (error) {
         console.error("Error sending notes:", error.message);
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al guardar tus notas.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
        });
      } finally {
         setloading(false);
      }
   };
   return {
      loading,
      loadingDownloads,
      downloadNotes,
      editor,
      handleSaveNotes,
      notes,
   }
}
