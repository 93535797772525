import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./Courses.css";
import CoursesMain from '../../features/Courses/CoursesMain';

const Courses = () => {
    return (
        <MainLayout>
            <CoursesMain/>
        </MainLayout>
    );
};
export default Courses;
