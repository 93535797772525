import { useContext, useEffect, useState } from "react";
import "./DailyRecommendationCard.css";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import RecommendedVet from "../../../assets/DailyRecommendation/RecommendedVet.png";

const DailyRecommendationCard = () => {
   const [recommendations, setRecommendations] = useState([]);
   const [currentRecommendation, setCurrentRecommendation] = useState(null);
   const { authToken } = useContext(AuthContext);

   const fetchRecommendations = async () => {
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/advices`,
            {
               headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + authToken,
               }
            }
         );
         const formattedResponse = response.data.map((recommended) => {
            return {
               advice: recommended.advice,
               id: recommended._id,
               lastname: recommended.user.lastname,
               name: recommended.user.name,
               type: recommended.user.type,
               image: recommended.user.profilePicture,
            };
         });
         setRecommendations(formattedResponse);
      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al intentar obtener las recomendaciones diarias.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })
         console.error("Error fetching recommendation data:", error);
      }
   };

   useEffect(() => {
      fetchRecommendations();
   }, []);

   useEffect(() => {
      if (recommendations.length > 0) {
         const randomIndex = Math.floor(Math.random() * recommendations.length);
         setCurrentRecommendation(recommendations[randomIndex]);
      }
   }, [recommendations]);

   return (
      <>
         <h3 className="dailyRecomendationCard-header">Consejo del día:</h3>
         <div className="dailyRecomendationCard-testimonial-card">
            <img
               alt="consejo"
               className="dailyRecomendationCard-testimonial-image"
               src={
                  currentRecommendation?.image
                     ? currentRecommendation.image
                     : RecommendedVet
               }
            />
            <div className="dailyRecomendationCard-testimonial-details">
               <p className="dailyRecomendationCard-recomendation">
                  "{currentRecommendation ? currentRecommendation.advice : "No hay consejos disponibles para el día de hoy. Regresa mañana para otro consejo del equipo de FORVET."}"
               </p>
               <p className="dailyRecomendationCard-author">
                  {currentRecommendation
                     ? `${currentRecommendation.name} ${currentRecommendation.lastname}`
                     : ""}
               </p>
            </div>
         </div>
      </>
   );
};

export default DailyRecommendationCard;
