import { useEditor } from "@tiptap/react"
import Image from '@tiptap/extension-image';
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Placeholder from "@tiptap/extension-placeholder";
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import TextAlign from "@tiptap/extension-text-align";
import LinkTipTap from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import Highlight from '@tiptap/extension-highlight';
import DOMPurify from "dompurify";
import { useCallback, useContext, useState } from "react";
import showNotification from "../utils/showNotification";
import { ERROR_HANDLER } from "../utils/error-handler";
import AuthContext from "../contexts/AuthContext";
import AppContext from "../contexts/AppContext";
import axios from "axios";

export default function useRichTextEditor({
   placeholder = '',
   content = '',
   editable = true
}) {

   const cleanContent = DOMPurify.sanitize(content);

   const editor = useEditor({
      extensions: [
         StarterKit,
         Underline,
         LinkTipTap,
         Superscript,
         Subscript,
         Highlight,
         TextStyle,
         Color,
         Image,
         Placeholder.configure({
            placeholder,
         }),
         TextAlign.configure({ types: ["heading", "paragraph"] }),
         Heading.configure({
            levels: [1, 2, 3, 4],
            HTMLAttributes: {
               class: 'RichTextEditor--heading'
            }
         }),
         Paragraph.configure({
            HTMLAttributes: {
               class: 'RichTextEditor--paragraph'
            }
         }),
         Image.configure({
            HTMLAttributes: {
               class: 'RichTextEditor--image'
            },
            allowBase64: true,
         })
      ],
      content: cleanContent,
      editable
   });

   const [isFileLoaded, setIsFileLoaded] = useState(2)
   const [state, setState] = useState({ isLoading: false })
   const { authToken } = useContext(AuthContext)
   const { currentUser } = useContext(AppContext)


   const addImage = useCallback(async (file) => {

      const ALLOWED_FILES_TYPES = ['image/jpeg']
      const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024 // 5 mb

      if (file?.size > MAX_SIZE_IN_BYTES) return showNotification({
         color: "red",
         status: "error",
         title: "Ha ocurrido un problema al cargar la imagen.",
         message: `Detalle: la imagen es demasiado grande. Prueba con otra mas pequeña.`
      })
      if (!ALLOWED_FILES_TYPES.includes(file?.type)) return showNotification({
         color: "red",
         status: "error",
         title: "Ha ocurrido un problema al cargar la imagen.",
         message: `El archivo debe ser en formato JPG, JPEG.`
      })

      if(isFileLoaded === 0) return showNotification({
         color: "red",
         status: "error",
         title: "Ha ocurrido un problema al cargar la imagen.",
         message: `Ya has subido 2 imágenes.`
      })

      const base64Image = await new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onloadend = () => {
            resolve(reader.result);
         };
         reader.onerror = reject;
         reader.readAsDataURL(file);
      });

      try {
         setState({ ...state, isLoading: true })
         const blob = await fetch(base64Image).then((res) => res.blob());
         const formData = new FormData()
         const directory = "public/images/forum";
         const name = `${currentUser.name}_${currentUser.lastname}-${Math.floor(Math.random() * 1000) + 1}.jpeg`

         formData.append('directory', directory)
         formData.append('name', name)
         formData.append('file', blob)

         const aws_response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}admin/uploadToS3`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               "Authorization": "Bearer " + authToken
            },
         });


         const url_AWS_image = aws_response.data.url

         editor.chain().focus().setImage({ src: url_AWS_image }).run()
         
         setIsFileLoaded(prevState => prevState - 1)
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al cargar la imagen.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`
         })
      } finally {
         setState({ ...state, isLoading: false })
      }

   }, [editor, authToken])

  return {
      editor,
      addImage,
      isLimitedImages: isFileLoaded === 0,
      state
  }
}
