import {Button} from '@mantine/core';
import './ExamScoreComponent.css';
import ExamEndedGif from '../../assets/ExamAssets/ExamEndedgif.gif';
import ExamEndedCat from '../../assets/ExamAssets/ExamEndedCat.gif';
import {useNavigate} from 'react-router';
import { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import moment from 'moment';
import 'moment/locale/es-us';

const ExamScoreComponent = ({title, percentage, approvalPercentage, setReviewMode, recoveryExamDate}) => {
    const navigate = useNavigate();
    const { currentUser } = useContext(AppContext);

    const redirectToExamReview = () => {
        setReviewMode(true);
    }

    const redirectToDashboard = () => {
        navigate(`/campus/dashboard`)
    }

    const getResults = () => {
        if (percentage >= approvalPercentage) {
            return 'Aprobado'
        } else {
            return 'Desaprobado'
        }
    }

    const getClassnames = () => {
        if (percentage >= approvalPercentage) {
            return 'examScoreComponent-percentage-approved'
        } else {
            return 'examScoreComponent-percentage-failed'
        }
    }

    const animalImages = {
        dog: ExamEndedGif,
        cat: ExamEndedCat
    };
    //TODO: Add information about recovery exam if the user failed the exam
    return (
        <div className='examScoreComponent-container'>
            <div className='examScoreComponent-father-div'>
                <div className='examScoreComponent-title-div'>
                    <h3 className='examScoreComponent-title-content'>Completaste el examen: {title}</h3>
                </div>
                <div className='examScoreComponent-image-div'>
                <img className='examScoreComponent-image' src={animalImages[currentUser.animalType]} alt={`${currentUser.animalType}_feliz`} />
                </div>
                <div className='examScoreComponent-percentage-div'>
                    <p className='examScoreComponent-yourNote-text'>Tu Nota:</p>
                    <p className={getClassnames()}>{percentage.toFixed(1)}%</p>
                </div>
                <div className='examScoreComponent-percentage-div'>
                    <p className='examScoreComponent-yourNote-text'>Resultado:</p>
                    <p className={getClassnames()}>{getResults()}</p>
                </div>
                {
                percentage < approvalPercentage && recoveryExamDate && <div className='examScoreComponent-recoveryDate-div'>
                <h5 className='examScoreComponent-recoveryDate-header'>¡No te desanimes!</h5> 
                <p className='examScoreComponent-recoveryDate'> Tendrás una nueva oportunidad para recuperar tu examen el día <span className='examScoreComponent-recoveryDate-span'>{moment(recoveryExamDate).locale('es').format('LL')}.</span></p>
                </div>
                }
                <div className='examScoreComponent-buttons-div'>
                    <Button onClick={() => redirectToDashboard()}
                            color={"var(--color-primary)"}
                            cx={"var(--color-primary)"}
                            classNames={{root: "examsScoreComponent-firstButton-root"}}
                            variant="outline">
                        Finalizar proceso
                    </Button>
                    <Button onClick={() => redirectToExamReview()}
                            bg={"var(--color-primary)"}>
                        Revisar respuestas
                    </Button>
                </div>
            </div>

        </div>
    )
}
export default ExamScoreComponent