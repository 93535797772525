import './ForumRichText.css'
import { IconArrowLeft, IconPhotoPlus } from "@tabler/icons-react";
import { Button, FileButton, Group, Loader } from "@mantine/core";
import RichText from '../../../components/RichText/RichText';
import useRichTextEditor from '../../../hooks/useRichTextEditor';
import DOMPurify from 'dompurify';

export default function ForumRichText({ handleSubmit, setIsRichText }) {

   const { state, isLimitedImages, editor, addImage } = useRichTextEditor({ placeholder: "Escribe tu mensaje" })

   if (!editor) {
      return null
   }

   return (
      <section className="ForumRichText-container">
         <Button w={'max-content'} variant='light' onClick={() => setIsRichText(false)} disabled={state.isLoading}>
            < IconArrowLeft />
            <span>Respuesta simple</span>
         </Button>

         < RichText
            editor={editor}
            minHeight='300px'
         />

         <footer className="ForumRichText-footer-container">
            <FileButton onChange={addImage} accept="image/jpeg" >
               {(props) => <Button {...props} color='var(--color-tertiary)' variant='outline' disabled={state.isLoading || isLimitedImages} >
                  {state.isLoading ? < Loader size={26} color="var(--color-primary)" /> : <IconPhotoPlus />}
               </Button>}
            </FileButton>
            <Group justify="center" align='center'>

               <Button onClick={() => handleSubmit(DOMPurify.sanitize(editor.getHTML()))} disabled={state.isLoading || editor.isEmpty}>Enviar</Button>

            </Group>
         </footer>
      </section>
   )
}