import React, {useContext} from "react";
import AuthContext, {
    withAuthContextProvider,
    withAuthContextReady,
} from "../contexts/AuthContext";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {useNavigate} from "react-router";

const ProtectedRoute = ({ component, ...args }) => {
    const {isAuth} = useContext(AuthContext)
    const navigate = useNavigate();

    if (!isAuth) navigate('/');

    const Component = withAuthenticationRequired(component, args);
    return <Component />;
};

export default withAuthContextProvider(withAuthContextReady(ProtectedRoute));
