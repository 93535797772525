import React, {useState} from "react";
import AppRoutes from "./routes/AppRoutes";
import {BrowserRouter} from "react-router-dom";
import {Auth0ProviderWithRedirectCallback} from "./routes/Auth0ProviderWithRedirectCallback";

const App = () => {
    const [theme, setTheme] = useState(1);
    const themesDict = {
        0: "dark-theme",
        1: "light-theme",
    };
    return (
        <div className={themesDict[theme]}>
            <BrowserRouter>
                <Auth0ProviderWithRedirectCallback
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                    authorizationParams={{
                        redirect_uri: `${window.location.origin}/callback`,
                    }}
                >
                    <AppRoutes/>
                </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
        </div>
    );
};

export default App;
