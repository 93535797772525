import './AboutClassInfoCard.css'
import moment from "moment";

export default function AboutClassInfoCard({date}) {
    const localDate = moment.utc(date).local();

    const classDate = localDate.format("DD/MM/YYYY");
    const classTime = localDate.format("HH:mm");

   const classDuration = 2
   
   return (
      <article className='ClassDetails-card-container'>
         <div className='ClassDetails-card--row'>
            <h5 className='ClassDetails-card-headline'>Sobre la clase</h5>
         </div>

         <div className='ClassDetails-card--row AboutClassInfoCard-rowItem'>
            <h6 className='AboutClassInfoCard-row--text'>Fecha de dictado:</h6>
            <span className='AboutClassInfoCard-row--data'>{classDate}</span>
         </div>

         <div className='ClassDetails-card--row ClassDetails--decorativeLine'></div>

         <div className='ClassDetails-card--row AboutClassInfoCard-rowItem'>
            <h6 className='AboutClassInfoCard-row--text'>Hora dictado:</h6>
            <span className='AboutClassInfoCard-row--data'>{classTime} hs</span>
         </div>

         <div className='ClassDetails-card--row ClassDetails--decorativeLine'></div>

         <div className='ClassDetails-card--row AboutClassInfoCard-rowItem'>
         <h6 className='AboutClassInfoCard-row--text'>Duración:</h6>
            <span className='AboutClassInfoCard-row--data'>{classDuration} hs</span>
         </div>

         <div className='ClassDetails-card--row ClassDetails--decorativeLine'></div>
         
      </article>
   )
}
