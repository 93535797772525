import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { VALIDATIONS } from "../utils/validations-functions"
import { useForm } from "@mantine/form"
import AuthContext from "../contexts/AuthContext/AuthContext"
import showNotification from "../utils/showNotification"
import { ERROR_HANDLER } from "../utils/error-handler"

export default function useCertificateSurvey({ acaUnit_id, setReFetch, setIsModal }) {

   const { authToken } = useContext(AuthContext)

   const [state, setState] = useState({
      isLoading: false,
      error: {
         isError: false,
         onModal: null,
         onComponent: null
      }
   })
   const [teachersFromBack, setTeachersFromBack] = useState([])
   const [_teachers, _setTeachers] = useState([])
   const [isAlreadyRated, setIsAlreadyRated] = useState(false)

   // obtiene los datos de la encuensta (profesores)
   useEffect(() => {
      const getSurveyInfo = async () => {
         try {
            setState(prevState => ({ ...prevState, isLoading: true }))

            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/certificates/survey/${acaUnit_id}`, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })

            setTeachersFromBack(data)
         } catch (err) {
            console.error(err)
            // showNotification({
            //    color: "red",
            //    status: "error",
            //    title: "Ha ocurrido un problema al obtener los datos necesarios para la encuesta.",
            //    message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`
            // })
            setState(prevState => ({
               ...prevState, error: {
                  isError: true,
                  onModal: 'Lo siento, no hemos podido obtener los datos necesarios para la encuesta. Intenta nuevamente.',
                  onComponent: null
               }
            }))

         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      }

      getSurveyInfo()
   }, [authToken, acaUnit_id])


   const form = useForm({
      initialValues: {
         teachersCommit: '',
         moderation: '',
         forVetExperience: '',
         webCampusValue: '',
         extraCommit: ''
      },
      validate: {
         teachersCommit: VALIDATIONS.teachersCommit,
         modetarion: VALIDATIONS.moderation,
         forVetExperience: VALIDATIONS.forVetExperience,
         webCampusValue: VALIDATIONS.webCampusValue,
         extraCommit: VALIDATIONS.extraCommit,
      },
   })


   const handleRateTeachers = (teacherName, value) => {
      _setTeachers(prevState => {

         const teacherExists = prevState.some(teacher => teacher.name === teacherName)

         if (!teacherExists) {
            return [...prevState, { name: teacherName, value }];
         }

         return prevState;
      })
   }

   const onSelectRate = (field, value) => {
      form.setFieldValue(field, value)
   }

   // envia la encuesta
   const handleSubmit = async () => {
      const { teachersCommit, moderation, forVetExperience, webCampusValue, } = form.values
      const isValid = (teachersCommit && moderation && forVetExperience && webCampusValue) ? true : null

      if (!isValid) {
         setState(prevState => ({
            ...prevState,
            error: {
               isError: true,
               onComponent: 'Lo siento, no has completado todos los campos.',
               onModal: null
            }
         }))
         return
      }
      if (_teachers.length !== teachersFromBack.length) {
         setState(prevState => ({
            ...prevState,
            error: {
               isError: true,
               onComponent: 'Te ha faltado valorar al menos a un profesor.',
               onModal: null
            }
         }))
         return
      }

      setState(prevState => ({ ...prevState, isLoading: true }))

      try {
         const { teachersCommit, moderation, forVetExperience, webCampusValue, extraCommit } = form.values
         const review = {
            acaUnit: acaUnit_id,
            data: {
               teachersCommit,
               moderation,
               forVetExperience,
               webCampusValue,
               extraCommit,
               teachers: _teachers
            }
         }

         await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}campus/users/survey`, { review }, {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${authToken}`,
            }
         })
         showNotification({
            color: "green",
            status: "success",
            title: "La encuesta ha sido enviada correctamente.",
            message: `Detalle: Ahora serás capaz de descargar el certificado. En caso de que no puedas recarga la página.`
         })
         setReFetch(prevState => !prevState)
         // setIsAlreadyRated(true)
         setIsModal(false)

      } catch (err) {
         console.error(err)
         // setState(prevState => ({
         //    ...prevState,
         //    error: {
         //       isError: true,
         //       onModal: 'Lo siento, ha ocurrido un error'
         //    }
         // }))
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al enviar la encuesta.",
            message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })
      } finally {
         setState(prevState => ({ ...prevState, isLoading: false }))
      }
   }

   return {
      state,
      teachersFromBack,
      _teachers,
      isAlreadyRated,
      handleRateTeachers,
      onSelectRate,
      handleSubmit,
      form
   }
}
