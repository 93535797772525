import HelpBody from '../../features/Help/HelpBody/HelpBody';
import './Help.css';
import MainLayout from "../../layouts/MainLayout/MainLayout";

const Help = () => {
  return (
    <MainLayout>
        <HelpBody/>
    </MainLayout>
  )
}
export default Help