export const USER_PROFILE_CAROUSEL_IMAGES = {
   cat: 
      [
         'https://img.freepik.com/psd-gratis/hermoso-gato-retrato-aislado_23-2150186022.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/gato-rojo-o-blanco-i-estudio-blanco_155003-13189.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/lindo-gato-relajante-al-aire-libre_23-2150692920.jpg?size=626&ext=jpg',
         'https://img.freepik.com/psd-gratis/hermoso-gato-retrato-aislado_23-2150186061.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/vista-gatito-aspecto-adorable_23-2150886340.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/primer-plano-gato-jengibre-abrazando-lamiendo-al-otro-aislado-pared-blanca_181624-32893.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/adorable-gato-relajante-interior_23-2150692709.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/lindo-gato-relajante-estudio_23-2150692717.jpg?size=626&ext=jpg'
      ],
   dog: 
      [
         'https://img.freepik.com/foto-gratis/pequeno-jack-russell-terrier-sentado-blanco_155003-10026.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/disparo-vertical-enfoque-superficial-lindo-cachorro-golden-retriever-sentado-suelo-hierba_181624-27259.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/adorable-perro-basenji-marron-blanco-sonriendo-dando-maximo-cinco-aislado-blanco_346278-1657.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/retrato-lindo-perro-alegre-lengua-fuera-posando-aislado-sobre-fondo-blanco-estudio_155003-45769.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/perro-raza-pura-siendo-lindo-estudio_23-2149016897.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/perro-pug-aislado-fondo-blanco_2829-11416.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/retrato-perro-encantador-mirando-otro-lado_23-2148366865.jpg?size=626&ext=jpg',
         'https://img.freepik.com/foto-gratis/retrato-perro-adorable-mirando-arriba_23-2148366909.jpg?size=626&ext=jpg'

      ]

}