import { Splide, SplideSlide } from '@splidejs/react-splide';
import AvalItem from './AvalItem';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Skeleton } from '@mantine/core';


export default function AvalCarousel({ itemsToMap, state }) {

   const { width } = useWindowDimensions();
   const isMobile = width <= 768;
   const itemPerPage = isMobile ? 3 : 5

   const skeletonStyles = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '40px'
   }

   return (
      <>

         {state.isLoading 
            ? <div style={skeletonStyles}>
               < Skeleton circle w={100} h={100} />
               < Skeleton circle w={100} h={100} />
               < Skeleton circle w={100} h={100} />
               < Skeleton circle w={100} h={100} />
               < Skeleton circle w={100} h={100} />

            </div>
            : <Splide
               options={{
                  type: 'loop',
                  width: '100%',
                  height: '100%',
                  loop: true,
                  autoplay: true,
                  drag: true,
                  interval: 2500,
                  perPage: itemPerPage,
                  perMove: 1,
                  pagination: false
               }}
            >
               {itemsToMap?.map((item) => (
                  <SplideSlide key={item._id}>
                     <AvalItem item={item} />
                  </SplideSlide>
               ))}
            </Splide>}

      </>
   )
}
