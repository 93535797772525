import {
  IconBook,
  IconDeviceDesktopUp,
  IconRefreshAlert,
  IconVideoPlus,
} from "@tabler/icons-react";
import "./DasboardRemindCard.css";
import moment from "moment";
import "moment/locale/es";

const DasboardRemindCard = ({
  notificationTitle,
  notificationType,
  notificationMessage,
  notificationDate = null,
}) => {
  let cardContent;
  switch (notificationType) {
    case "Exams":
      cardContent = (
        <div className="dasboardRemindCard-father-div">
          <div className="dasboardRemindCard-cardColor"></div>
          <div className="dasboardRemindCard-generalInformation">
            <IconDeviceDesktopUp className="dasboardRemindCard-generalInformation-icon" />
            <div className="dasboardRemindCard-generalInformation-wrapper">
              <h3 className="dasboardRemindCard-generalInformation-title">
                {notificationTitle}
              </h3>
              <p className="dasboardRemindCard-generalInformation-text">
                {notificationMessage}
              </p>
              <div className="dasboardRemindCard-extraInformation">
                {notificationDate !== null && (
                  <div className="dasboardRemindCard-extraInformation-divSpan">
                    <span className="dasboardRemindCard-extraInformation-span">
                      Fecha:
                    </span>
                    <p className="dasboardRemindCard-extraInformation-text">
                      {moment(notificationDate)
                        .locale("es")
                        .format("DD MMM YYYY, h:mm a")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case "Materials":
      cardContent = (
        <div className="dasboardRemindCard-father-div">
          <div className="dasboardRemindCard-cardColor"></div>
          <div className="dasboardRemindCard-generalInformation">
            <IconBook className="dasboardRemindCard-generalInformation-icon" />
            <div className="dasboardRemindCard-generalInformation-wrapper">
              <h3 className="dasboardRemindCard-generalInformation-title">
                {notificationTitle}
              </h3>
              <p className="dasboardRemindCard-generalInformation-text">
                {notificationMessage}
              </p>
            </div>
          </div>
        </div>
      );
      break;

    case "Lesson":
      cardContent = (
        <div className="dasboardRemindCard-father-div">
          <div className="dasboardRemindCard-cardColor"></div>
          <div className="dasboardRemindCard-generalInformation">
            <IconRefreshAlert className="dasboardRemindCard-generalInformation-icon" />
            <div className="dasboardRemindCard-generalInformation-wrapper">
              <h3 className="dasboardRemindCard-generalInformation-title">
                {notificationTitle}
              </h3>
              <p className="dasboardRemindCard-generalInformation-text">
                {notificationMessage}
              </p>
            </div>
          </div>
        </div>
      );

    case "Record":
      cardContent = (
        <div className="dasboardRemindCard-father-div">
          <div className="dasboardRemindCard-cardColor"></div>
          <div className="dasboardRemindCard-generalInformation">
            <IconVideoPlus className="dasboardRemindCard-generalInformation-icon" />
            <div className="dasboardRemindCard-generalInformation-wrapper">
              <h3 className="dasboardRemindCard-generalInformation-title">
                {notificationTitle}
              </h3>
              <p className="dasboardRemindCard-generalInformation-text">
                {notificationMessage}
              </p>
            </div>
          </div>
        </div>
      );
      break;

    default:
      cardContent = null;
  }

  return <div>{cardContent}</div>;
};

export default DasboardRemindCard;
