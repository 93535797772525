import './PrevExamInfoCard.css'
import { Button, Tooltip } from '@mantine/core';
import { IconBook, IconHourglassEmpty, IconCheck, IconQuestionMark, IconTrophy, IconDeviceDesktop } from '@tabler/icons-react';
import PrevExamInfoItem from '../PrevExamInfoItem/PrevExamInfoItem';
import DecorativeEmptyCircle from '../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle';
import { useState } from 'react';
import ConfirmExamModal from '../ConfirmExamModal/ConfirmExamModal';
import { useNavigate } from 'react-router-dom';


export default function PrevExamInfoCard({
   id,
   headerTitle = 'Evaluación',
   duration,
   tryQuantity,
   questionCuantity,
   approvalPercentage,
   hasStartedExam,
   hasEndedSessionTime
}) {

   const navigate = useNavigate()
   const [isModal, setIsModal] = useState(false)

   const handleClick = () => {
      setIsModal(false)
      navigate(`/campus/examen/${id}`)
   }

   const renderInitButton = () => {
      if (!hasStartedExam) {
         // If the user has not started the exam, we show the button to start it
         return <Button h='50px' w={{ base: '100%', sm: '200px' }}
            onClick={() => setIsModal(true)}
            color='var(--color-primary-dark)'>
            Iniciar evaluación
         </Button>
      } else if (!hasEndedSessionTime) {
         // If the user has started the exam, but has not finished the time, we show the button to continue it
         return <Button h='50px' w={{ base: '100%', sm: '200px' }}
            onClick={() => handleClick()}
            color='var(--color-primary-dark)'>
            Continuar evaluación
         </Button>
      } else {
         // If the user has started the exam, but the time has ended, we show the button disabled
         return <Tooltip label="No quedan intentos" position="right">
            <Button h='50px' w={{ base: '100%', sm: '200px' }} color='var(--color-primary-dark)' disabled>
               Iniciar evaluación
            </Button>
         </Tooltip>
      }
   }

   return (
      <>
         <div className='PrevExamPage-infoCard-container'>
            <div className='PrevExamPage-infoCard--content'>

               <header className='PrevExamPage-infoCard-header'>
                  <div className='PrevExam-icon-container'>
                     <IconBook size={50} color='var(--color-secondary)' />
                  </div>
                  <h4 className='PrevExam-headerTitle'>{headerTitle}</h4>
               </header>


               <div className='PrevExamPage-infoCard-introduction'>
                  <h5>Consideraciones</h5>
                  <ul className='PrevExamPage-infoCard-introduction-textGroup'>
                     <li>Antes de comenzar la evaluación es importante que veas los detalles de la misma.</li>
                     <li>Recordá que una vez empiece, el reloj comenzará a correr.</li>
                     <li>Tus respuestas serán enviadas por única vez al finalizar el examen. No son registradas
                        una a una a medida que se avanza. Puedes revisarlas cuantas veces necesites.
                     </li>
                  </ul>
               </div>


               {duration &&
                  < PrevExamInfoItem
                     icon={< IconHourglassEmpty
                        size={24}
                        className='PrevExamPage-infoCard--icon' />}
                     headline='Duración:'
                     text={`${duration} minutos`} />
               }

               {tryQuantity &&
                  < PrevExamInfoItem
                     icon={< IconCheck size={24}
                        className='PrevExamPage-infoCard--icon PrevExamPage--borderIcon' />}
                     headline='Cantidad total de intentos disponibles:'
                     text={tryQuantity} />
               }

               {questionCuantity &&
                  < PrevExamInfoItem
                     icon={< IconQuestionMark
                        size={24}
                        className='PrevExamPage-infoCard--icon PrevExamPage--borderIcon' />}
                     headline='Cantidad de preguntas:'
                     text={questionCuantity} />
               }

               {approvalPercentage &&
                  < PrevExamInfoItem
                     icon={< IconTrophy
                        size={24}
                        className='PrevExamPage-infoCard--icon ' />}
                     headline='Porcentaje mínimo de aprobación:'
                     text={`${approvalPercentage}%`} />
               }

               < PrevExamInfoItem
                  icon={< IconDeviceDesktop
                     size={24}
                     className='PrevExamPage-infoCard--icon ' />}
                  headline='Para una mejor experiencia al rendir el exámen te recomendamos que utilices una computadora '
                  />

            </div>

            <footer className='PrevExamPage-infoCard-footer'>
               {renderInitButton()}
            </footer>

            < DecorativeEmptyCircle size={300} borderWidth={45} opacity={.15} top={60} right={-150}
               color='var(--color-secondary)' />
            < DecorativeEmptyCircle size={200} borderWidth={40} opacity={.15} bottom={-100} right={150}
               color='var(--color-secondary)' />

         </div>
         < ConfirmExamModal isModal={isModal} setIsModal={setIsModal} handleClick={handleClick} />
      </>
   )
}
