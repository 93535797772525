import { Accordion, Paper, Skeleton } from "@mantine/core";

const ValidateCertificateSkeleton = () => {
  return (
    <div  className="validateCertificateSkeleton-layout">
      <Paper padding="lg" shadow="lg" className="validatedCertificate-paper">
        <div style={{height:"100%"}} className="validatedCertificate-title-skeleton">
          <Skeleton height={105} />
        </div>
        <div className="validatedCertificate-content-skeleton">
          <div className="validateCertificate-accordion-skeleton">
            <h3 className="validateCertificate-accordion-header-skeleton">
              <Skeleton height={200} width={200} />
            </h3>
            <Accordion variant="separated" transitionDuration={1000}>
              {[1, 2, 3].map((index) => (
                <Accordion.Item key={index} value={`Item ${index}`}>
                  <Accordion.Control>
                    <Skeleton height={20} width={100} />
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="validateCertificate-accordion-panel-skeleton">
                      <Skeleton height={200} />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="validatedCertificate-topics-skeleton">
            <Skeleton style={{marginBottom:"16px"}} height={80} width={200} />
            <Skeleton height={80} width={200} />
            <Skeleton height={80} width={200} />
            <Skeleton height={80} width={200} />
            <Skeleton height={80} width={200} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default ValidateCertificateSkeleton;
