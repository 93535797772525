import React, { useState, useEffect, useContext } from "react";
import "./HelpBody.css";
import { Accordion, AccordionItem } from "@mantine/core";
import axios from "axios";
import HelpHeader from "../HelpHeader/HelpHeader";
import HelpSkeleton from "../HelpSkeleton/HelpSkeleton";
import AuthContext from "../../../contexts/AuthContext";
import { IconUserQuestion } from "@tabler/icons-react";
import AdvancedQueryForm from "../../../components/Help/AdvancedQueryForm";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";

const HelpBody = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [themes, setThemes] = useState([]);
  const { authToken } = useContext(AuthContext);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}campus/faqs/campus`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      const data = response?.data;
      const allThemes = data.map((question) => question.category);
      const uniqueThemes = Array.from(new Set(allThemes));
      setThemes(uniqueThemes);
      setQuestions(data);
      setFilteredQuestions(data);
    } catch (error) {
      console.error("Error fetching Help Center data:", error);
      showNotification({
         color: "red",
         status: "error",
         title: "Ha ocurrido un problema al cargar las preguntas frecuentes.",
         message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
         autoClose: 12000
     })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (searchTerm) => {
    const filtered = questions?.filter(
      (question) =>
        question.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  };

  const handleThemeSearch = (selectedTheme) => {
    if (selectedTheme) {
      const filtered = questions?.filter(
        (question) => question.category === selectedTheme
      );
      setFilteredQuestions(filtered);
    } else {
      setFilteredQuestions(questions);
    }
  };

  return (
    <div className="helpBody-container">
      {loading === true ? (
        <HelpSkeleton />
      ) : (
        <>
          <HelpHeader
            onThemeSearch={handleThemeSearch}
            onSearchChange={handleSearchChange}
            themes={themes}
          />
          {!filteredQuestions || filteredQuestions.length === 0 ? (
            <AdvancedQueryForm />
          ) : (
            <div className="helpBody-accordion-div">
              <Accordion
                transitionDuration={1000}
                classNames={{
                  control: "helpBody-accordion-control",
                  panel: "helpBody-accordion-panel",
                }}
              >
                {filteredQuestions.map((question) => (
                  <AccordionItem
                    category={question.category}
                    value={question._id}
                    title={question.question}
                  >
                    <Accordion.Control icon={<IconUserQuestion />}>
                      {question.question}
                    </Accordion.Control>
                    <Accordion.Panel> {question.answer} </Accordion.Panel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HelpBody;
