import './LoginCarousel.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import LoginSlide from '../LoginSlide/LoginSlide'
import { Skeleton } from '@mantine/core'
import { capitalize } from '../../../utils/capitalize'

export default function LoginCarousel({ acaUnits, state }) {

   if(state.error) return null

   return (
      <div className='LoginPage-carousel-container'>
         {state.isLoading 
         ? < Skeleton h={350} w='100%'/>

         : <Splide
         options={{
            rewind: true,
            width: '100%',
            gap: "1rem",
            pagination: false,
            height: '100%',
         }}
      >
         {acaUnits?.map(item => (
            <SplideSlide key={item._id}  >
               < LoginSlide 
                  name={item.comercialInformation.name} 
                  description={capitalize(item.comercialInformation.description.toLowerCase())}   
                  id={item._id}
               />
            </SplideSlide>
         ))}
      </Splide>
         
         }
      </div>
   )
}
