import './Sidebar.css'
import SidebarNav from '../SidebarNav/SidebarNav'
import SidebarFooter from '../SidebarFooter/SidebarFooter'
import SidebarHeader from '../SidebarHeader/SidebarHeader'
import DecorativeEmptyCircle from '../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle'
import {IconLayoutSidebarLeftExpand} from '@tabler/icons-react'
import {useContext, useEffect} from 'react'
import AppContext from "../../../contexts/AppContext";
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import CalendarAside from '../../../components/CalendarAside/CalendarAside'


export default function Sidebar({isSidebarOpen, setIsSidebarOpen}) {
    const {isNavbarOpen} = useContext(AppContext);
    const {width} = useWindowDimensions();
    const isTablet = width < 1000;

    useEffect(() => {
        if (isTablet) {
            setIsSidebarOpen(false)
        }
    }, [isTablet])

   return (
      <aside className={`sidebar-container ${!isSidebarOpen ? '' : 'sidebar-collapsed'}`}>

            {isTablet && < CalendarAside/>}

            <section className={`sidebar-content ${!isNavbarOpen ? '' : 'navbar-open'}`}>

                < DecorativeEmptyCircle size={180} opacity={.1} top={-50} left={-50}
                                        color='var(--color-secondary)' borderWidth={32} hideInMobile/>
                < DecorativeEmptyCircle size={180} opacity={.1} top={250} right={-100}
                                        color='var(--color-secondary)' borderWidth={24} hideInMobile/>
                < DecorativeEmptyCircle size={100} opacity={.1} bottom={50} left={-40}
                                        color='var(--color-secondary)' borderWidth={16} hideInMobile/>

                < IconLayoutSidebarLeftExpand
                    size={32}
                    color='var(--color-base-light)'
                    className={`sidebar-back-icon ${isSidebarOpen ? '' : 'collapse'}`}
                    onClick={() => {setIsSidebarOpen(!isSidebarOpen)}}
                />

                < SidebarHeader/>
                < SidebarNav/>
                < SidebarFooter/>
            </section>
        </aside>
    )
}
