import "./ForumNewDiscussion.css";

import { Button, FileButton, Loader, Select, TextInput, Text } from "@mantine/core";
import DecorativeEmptyCircle from "../../DecorativeEmptyCircle/DecorativeEmptyCircle";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import showNotification from "../../../utils/showNotification";
import CourseClassHeaderNavigation from "../../CourseClassHeaderNavigation/CourseClassHeaderNavigation";
import AppContext from "../../../contexts/AppContext";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import useRichTextEditor from "../../../hooks/useRichTextEditor";
import RichText from "../../RichText/RichText";
import { IconPhotoPlus } from "@tabler/icons-react";
import DOMPurify from "dompurify";

const ForumNewDiscussion = ({ setReRender, reRender }) => {
  const { authToken } = useContext(AuthContext);
  const { discussionThemes, setDiscussionThemes } = useContext(AppContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [discussion, setDiscussion] = useState({
    topic: "",
    title: "",
    content: "",
  });
  const [selectOptions, setSelectOptions] = useState([]);
  const [titleError, setTitleError] = useState("");

  useEffect(() => {
    if (discussionThemes) {
      const options = discussionThemes.reduce((acc, item) => {
        if (typeof item === "string") {
          acc.push({ value: item, label: item });
        } else if (item.name) {
          acc.push({ value: item.id, label: item.name });
        }
        return acc;
      }, []);
      setSelectOptions(options);
    }
  }, [discussionThemes]);

  useEffect(() => {
    checkButtonState();
  }, [discussion]);

  const handleTopicChange = (value) => {
    setDiscussion((prevDiscussion) => ({
      ...prevDiscussion,
      topic: value,
    }));
  };

  const handleTitleChange = (value) => {
    setDiscussion((prevDiscussion) => ({
      ...prevDiscussion,
      title: value,
    }));
    if (value.length <= 5) {
      setTitleError("El título debe tener más de 5 caracteres.");
    } else {
      setTitleError("");
    }
  };

  const handleContentChange = (value) => {
    setDiscussion((prevDiscussion) => ({
      ...prevDiscussion,
      content: value,
    }));
  };

  const checkButtonState = () => {
    const { topic, title, content } = discussion;
    const isTopicSelected = topic !== "";
    const isTitleValid = title.length > 5;
    const isContentNotEmpty = content !== "";

    setIsButtonDisabled(
      !isTopicSelected || !isTitleValid || !isContentNotEmpty
    );
  };

  const handleButtonClick = async () => {
    const currentContent = DOMPurify.sanitize(editor?.getHTML());

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}campus/users/createDiscussion`,
        {
          data: {
            text: currentContent,
            theme: discussion.topic,
            title: discussion.title,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      setReRender(!reRender);
      showNotification({
        color: "green",
        status: "success",
        title: "¡El debate ha sido creado! Espera que algún colega colabore con tu debate!.",
      });
      setDiscussionThemes(undefined);
    } catch (error) {
      console.error("Error posting new discussion data:", error);
      showNotification({
        color: "red",
        status: "error",
        title: "Ha ocurrido un problema al crear una nueva discusión.",
        message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`
      });
    }
  };

  const { state, addImage, isLimitedImages, editor } = useRichTextEditor({
    placeholder: 'Escribe aquí la descripción de tu nueva discusión...'
  });

  return (
    <>
      <div className="forumNewDiscussion-father-div">
        <CourseClassHeaderNavigation onNavigate={() => setDiscussionThemes(undefined)} />
        <h2 className="forumNewDiscussion-header-title">
          Crear una nueva discusión.
        </h2>
        <div className="forumNewDiscussion-content-div">
          <DecorativeEmptyCircle
            size={100}
            opacity={0.1}
            top={500}
            left={1210}
            color="#77BDE0"
            borderWidth={24}
          />
          <DecorativeEmptyCircle
            size={80}
            opacity={0.1}
            top={-30}
            left={110}
            color="#77BDE0"
            borderWidth={16}
          />
          <DecorativeEmptyCircle
            size={200}
            opacity={0.1}
            top={500}
            left={780}
            color="#77BDE0"
            borderWidth={32}
          />
          <DecorativeEmptyCircle
            size={180}
            opacity={0.1}
            top={100}
            left={530}
            color="#77BDE0"
            borderWidth={32}
          />
          <DecorativeEmptyCircle
            size={180}
            opacity={0.1}
            top={200}
            left={1200}
            color="#77BDE0"
            borderWidth={32}
          />

          {discussionThemes && (
            <Select
              label="Primero elegí un tema para la nueva discusión."
              placeholder="Temas"
              onChange={handleTopicChange}
              data={selectOptions}
            />
          )}
          <TextInput
            label="Título de la nueva discusión"
            placeholder="Título"
            onChange={(event) => handleTitleChange(event.target.value)}
            error={titleError}
          />
          <div>
            <RichText changeValue={handleContentChange} editor={editor} classNames={{ root: "forumNewDiscussion-richTextEditor-root", content: "forumNewDiscussion-richTextEditor-content" }} />

            <Button
              classNames={{ root: "forumNewDiscussion-button" }}
              fw="normal"
              onClick={handleButtonClick}
              disabled={isButtonDisabled}
            >
              Cargar nueva discusión
            </Button>
            <FileButton onChange={addImage} accept="image/jpeg" >
              {(props) => <Button {...props} color='var(--color-tertiary)' variant='outline' disabled={state.isLoading || isLimitedImages} >
                {state.isLoading ? <Loader size={26} color="var(--color-primary)" /> : <IconPhotoPlus />}
              </Button>}
            </FileButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumNewDiscussion;
