import { Button, Modal } from '@mantine/core'
import './ForumRulesModal.css'
import { IconNotebook } from '@tabler/icons-react'
import { FORUM_RULES } from '../../../utils/forum-rules'


export default function ForumRulesModal({ isModal, setIsModal }) {
   return (
      <Modal
         opened={isModal}
         onClose={() => setIsModal(false)}
      >
         <main className='ForumRulesModal-main'>
            <header className='ForumRulesModal-header-container'>
               < IconNotebook size={80} color='#2C4B58' />
               <h3 className='ForumRulesModal-header--text'>Estas son la reglas del foro de FORVET</h3>
            </header>

            <section className='ForumRulesModal-content'>
               {FORUM_RULES.map(item => (
                  <article className='ForumRulesModal-item--container' key={item.title}>
                     <div className='ForumRulesModal-item--header'>
                        {item.icon}
                        <h4 className='ForumRulesModal-item--title'>{item.title}</h4>
                     </div>
                     <p className='ForumRulesModal-item--text'>{item.text}</p>
                  </article>
               ))}
            </section>

            <footer className='ForumRulesModal-footer-container'>
               <Button onClick={() => setIsModal(false)}>Aceptar y cerrar</Button>
            </footer>
         </main>

      </Modal>
   )
}
