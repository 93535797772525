import './RegisterPage.css'
import RegisterPageForm from '../../features/RegisterPage/RegisterPageForm/RegisterPageForm'
import forvetLogo from '../../assets/RegisterPage/logoForvet_dark.svg'
import DecorativeEmptyCircle from '../../components/DecorativeEmptyCircle/DecorativeEmptyCircle'

export default function RegisterPage() {

   return (
      <section className='RegisterPage-container'>

         <div className='RegisterPage-content'>

            < DecorativeEmptyCircle size={200} opacity={.2} bottom={-50} right={-50} color='#77BDE0' borderWidth={32} />
            < DecorativeEmptyCircle size={180} opacity={.2} top={-50} left={-50} color='#77BDE0' borderWidth={32} />
            < DecorativeEmptyCircle size={100} opacity={.2} top={220} left={100} color='#77BDE0' borderWidth={16} />
            < DecorativeEmptyCircle size={100} opacity={.1} top={50} right={40} color='#77BDE0' borderWidth={16} />

            <div className='RegisterPage-up-container'>
               < img src={forvetLogo} alt='logo de FORVET' />
               <h3 className='RegisterPage-left-title'>
                  Vamos a terminar tu registro
               </h3>
            </div>
            < RegisterPageForm />
         </div>

      </section>
   )
}
