import './CertificateSurvey.css'
import { Button, Loader, Skeleton, Textarea } from '@mantine/core'
import CertificateSurveyItem from '../CertificateSurveyItem/CertificateSurveyItem'
import { IconExclamationCircle, IconFaceIdError } from '@tabler/icons-react'
import useCertificateSurvey from '../../../hooks/useCertificateSurvey'

export default function CertificateSurvey({ 
   questions, 
   acaUnit_id, 
   setIsModal, 
   setReFetch 
}) {
   const { 
      state, 
      teachersFromBack, 
      handleRateTeachers, 
      onSelectRate, 
      handleSubmit, form 
   } = useCertificateSurvey({ acaUnit_id, setReFetch, setIsModal })

   return (
      <div className='CertificateSurvey-container' >
         {!state?.error.onModal &&
            <div className='GetCertificateModal--title-container'>
               < IconExclamationCircle size={150} color='#2C4B58' />
               <h3 className='GetCertificateModal--title'>Necesitamos que valides este curso antes de poder descargar el certificado</h3>
            </div>
         }
         {state?.isLoading &&
            <>
               < Skeleton w='90%' h={150} m={20} />
               < Skeleton w='90%' h={150} m={20} />
               < Skeleton w='90%' h={150} m={20} />
            </>
         }

         {(!state?.error.onModal && !state?.isLoading && teachersFromBack) &&
            <>
               {teachersFromBack?.map(teacher => {
                  const teacherName = `${teacher.name ?? ''} ${teacher.lastname ?? ''}`
                  return (
                     < CertificateSurveyItem
                        key={teacher._id}
                        question={`¿Que te pareció la participación del docente ${teacherName}?`}
                        onClick={({ target }) => handleRateTeachers(teacherName, target.value)}
                     />
                  )
               })}

               {questions.map(question => (
                  < CertificateSurveyItem
                     key={question._id}
                     question={question.q}
                     onClick={({ target }) => onSelectRate(question.formName, target.value)}
                  />
               ))}

               < Textarea
                  classNames={{ label: 'CertificateSurvey--textarea-label' }}
                  placeholder='Escribí tu mensaje'
                  py={20}
                  w={'90%'}
                  rows={3}
                  maxRows={5}
                  onChange={({ target }) => form.setFieldValue('extraCommit', target.value)} label='Aquí puedes dejarnos algún comentario:'
                  {...form.getInputProps('extraCommit')}
               />

               {state?.error.onComponent &&
                  <p className='CertificateSurvey-error--text'>{state?.error.onComponent}</p>
               }

               <Button
                  w={'90%'}
                  my={20}
                  onClick={handleSubmit}
                  disabled={state?.isLoading}
                  // bg='var(--color-tertiary-light)'
                  bg='#e1568b'
               >
                  {state?.isLoading ? < Loader color={'#fff'} size={20} /> : 'Enviar'}
               </Button>


            </>
         }

         {state?.error.onModal &&
            <div className='CertificateSurvey-error-container'>
               <div className='GetCertificateModal--title-container'>
                  <h3 className='GetCertificateModal--title'>Necesitamos que valides este curso antes de poder descargar el certificado</h3>
               </div>
               < IconFaceIdError size={150} color='#DC3D3D' />
               <p className='CertificateSurvey-error--text'>{state?.error.onModal}</p>
               <Button
                  onClick={() => setIsModal(false)}
               >
                  Cerrar
               </Button>
            </div>
         }
      </div>
   )
}
