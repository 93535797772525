import { IconBook } from '@tabler/icons-react'
import './ForumNoAcceptTermsAndConditions.css'

export default function ForumNoAcceptTermsAndConditions({ setIsModal }) {
   return (
      <div className='ForoPage-no-accepted-terms--container'>
         <IconBook color='var(--color-text-secondary)' size={150} />
         <p className='ForoPage-no-accepted-terms--text'>Para poder ver el foro, debes aceptar nuestros términos y condiciones</p>
         <div className='ForoPage-no-accepted-terms--group'>
            <p className='ForoPage-no-accepted-terms--text'>Podés verlos acá</p>
            <button className='ForoPage-no-accepted-terms--button' onClick={() => setIsModal(true)}>Términos y condiciones del foro</button>
         </div>
      </div>
   )
}
