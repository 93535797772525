import { useAuth0 } from "@auth0/auth0-react";
import SidebarNavLink from "../SidebarNavLink/SidebarNavLink";
import "./SidebarFooter.css";
import { IconLogout } from "@tabler/icons-react";

export default function SidebarFooter() {
  const { logout } = useAuth0();
  const logoutUrl = "https://campus.forvet.org/";

  return (
    <footer className="sidebar-footer-container">
      <SidebarNavLink
        icon={<IconLogout className="sidebar-navLink--icon" />}
        isButton
        text="Cerrar sesión"
        onClick={() => logout({ returnTo: logoutUrl })}
      />
    </footer>
  );
}
