import { IconUser } from '@tabler/icons-react'
import './AboutProfessorInfoContent.css'

export default function AboutProfessorInfoContent({ profilePicture, name, lastname, about }) {
   return (
      <div className='AboutProfessorInfoContent-container'>
         <div className='ClassDetails-card--row AboutProfessorCard-avatar-container'>
            <figure className='AboutProfessorCard-image-container'>
               {profilePicture 
                  ? < img className='AboutProfessorCard--image' src={profilePicture} alt='imagen del profesor' /> 
                  : < IconUser color='var(--color-primary-dark)' className='AboutProfessorCard--image' />
               }
            </figure>
            <div className='AboutProfessorCard-avatar-professorInfo'>
               <h5 className='AboutProfessorCard-avatar-professorInfo--name'>{`${name} ${lastname}`}</h5>
               <h6 className='AboutProfessorCard-avatar-professorInfo--specialty'>Médico Veterinario</h6>
            </div>
         </div>

         <div className='ClassDetails-card--row'>
            <p className='ClassDetails-card-text'>
               {about.description}
            </p>
         </div>
      </div>
   )
}
