import React, { useContext, useEffect, useState } from "react";
import "./Materials.css";
import { MaterialsTable } from "../../features/Courses/MaterialsTable/MaterialsTable";
import axios from "axios";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import AuthContext from "../../contexts/AuthContext";
import showNotification from "../../utils/showNotification";
import {useParams} from "react-router-dom";
import {Loader} from "@mantine/core";
import CourseClassHeaderNavigation from "../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation";
import { ERROR_HANDLER } from '../../utils/error-handler';


function Materials() {
  const { lessonId } = useParams();
  const [materialsData, setMaterialsData] = useState(null);
  const { authToken } = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/class/${lessonId}/materials`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    }
                }
            )
            setMaterialsData(response.data)
        } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al intentar cargar el material de la clase.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
        })
        }
    };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainLayout>
      <div className="materials__container">
        <CourseClassHeaderNavigation btnText="Volver a la clase"/>
        <h1 className="materials__title">Materiales de la clase</h1>
        <h2 className="materials__subtitle">
          Descarga los materiales de tu clase
        </h2>
        {!materialsData && <Loader />}
        {materialsData?.length === 0 && <p>No hay materiales disponibles</p>}
        {materialsData?.length > 0 && <MaterialsTable data={materialsData} />}
      </div>
    </MainLayout>
  );
}

export default Materials;
