import { useNavigate } from "react-router-dom";
import "./ValidateEmailTrue.css";
import { Button } from "@mantine/core";
const ValidateEmailTrue = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate ("/")
  };
  return (
    <div className="validateEmailTrue-father-div">
      <img
        className="validateEmailTrue-image"
        src="https://ugokawaii.com/wp-content/uploads/2023/12/rocket.gif"
        alt="Email validation success"
      />
      <h2 className="validateEmailTrue-firstTitle">¡Genial!</h2>
      <h3 className="validateEmailTrue-secondTitle">
        Tu email ha sido verificado correctamente.
      </h3>
      <p className="validateEmailTrue-text">
        Ya puedes cerrar esta pestaña y comenzar a vivir la experiencia FORVET.
      </p>
      <p className="validateEmailTrue-text">¡Que lo disfrutes!</p>
      <Button fw="normal" variant="outline" onClick={handleClick}>Ir al campus</Button>
    </div>
  );
};
export default ValidateEmailTrue;
