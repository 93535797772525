import { useLocation } from "react-router-dom";
import ValidateEmailTrue from "../../components/ValidateEmail/ValidateEmailTrue";
import ValidateEmailFalse from "../../components/ValidateEmail/ValidateEmailFalse";

const ValidateEmailHandler = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get("success");

  let message;
  if (success === "true") {
    message = <ValidateEmailTrue />;
  } else if (success === "false") {
    message = <ValidateEmailFalse />;
  } else {
    message = "Estado de verificación de email desconocido";
  }

  return (
    <div>
      {message}
    </div>
  );
};

export default ValidateEmailHandler;
