import { Skeleton } from '@mantine/core'
import './OpportunitiesSkeletonCard.css'

export default function OpportunitiesSkeletonCard() {
   return (
      <div className='OpportunitiesSkeletonCard-container'>
         < Skeleton h={300} w={'100%'} />

         <div className='OpportunitiesSkeletonCard-flex-section'>

            < Skeleton h={32} w={'300px'} />

            <div className='OpportunitiesSkeletonCard-grid'>
               <div className='OpportunitiesSkeletonCard-article'>
                  <Skeleton h={'125px'} w={'125px'} />

                  <div className='OpportunitiesSkeletonCard--texts'>
                     <Skeleton h={'18px'} w={'125px'} />

                     <Skeleton h={'16px'} w={'100%'} />
                     <Skeleton h={'16px'} w={'80%'} />
                  </div>

                  <Skeleton h={16} w={"100%"}/>


               </div>
            </div>
         </div>
      </div>
   )
}
