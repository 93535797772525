import './ForumCard.css'
import ForumCardHeader from '../ForumCardHeader/ForumCardHeader'
import ForumCardContent from '../ForumCardContent/ForumCardContent'
import ForumCardFooter from '../ForumCardFooter/ForumCardFooter'

export default function ForumCard({ discussionId, profilePicture, username, title, text, tag, commentAmount, bgColor, children, report, includeLink, bxShadow, hideCommentAmount, ForumPageContent }) {

   const styles = {
      backgroundColor: bgColor,
      boxShadow: bxShadow,
   }

   return (
      <article className='ForoCard-container' style={styles}>

         < ForumCardHeader profilePicture={profilePicture} username={username} commentAmount={commentAmount} hideCommentAmount={hideCommentAmount} />

         < ForumCardContent
            title={title}
            text={text}
            ForumPageContent={ForumPageContent}
            tag={tag}
            includeLink={includeLink}
            discussionId={discussionId}
         />

         < ForumCardFooter report={report} />

         {children}
      </article>
   )
}