import axios from "axios"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../contexts/AuthContext"
import AppContext from "../contexts/AppContext/AppContext"
import showNotification from "../utils/showNotification"
import { NO_AUTHORIZATION_CODE } from "../utils/forum-rules"
import { ERROR_HANDLER } from "../utils/error-handler"

export default function useForum() {
   const { authToken } = useContext(AuthContext)
   const { setDiscussionThemes } = useContext(AppContext)
   const [isDecrecent, setIsDecrecent] = useState(true)
   const [topics, setTopics] = useState(null)
   const [isModal, setIsModal] = useState(false)
   const [reRender, setReRender] = useState(false)

   const [state, setState] = useState({
      isLoading: false,
      error: {
         status: null,
         message: null
      }
   })

   useEffect(() => {
      const getForumTopics = async () => {
         setState(prevState => ({ ...prevState, isLoading: true }))
         const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/users/discussions`
         try {
            const { data } = await axios.get(url, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })
            setTopics(data)

            } catch (err) {
               console.error(err)
               if (err.request.status === NO_AUTHORIZATION_CODE) {
                  setIsModal(true)
                  return setState(prevState => ({ ...prevState, error: { message: null, status: NO_AUTHORIZATION_CODE } }))
               }
               setState(prevState => ({ ...prevState, error: { status: err.request.status, message: 'Lo siento, ha ocurrido un error al intentar cargar los tópicos a los que estas suscripto. Intenta nuevamente.' } }))
               showNotification({
                  color: "red",
                  status: "error",
                  title: "Ha ocurrido un problema al cargar los tópicos a los que estas suscripto.",
                  message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                  autoClose: 12000
              })
               
            } finally {
               setState(prevState => ({ ...prevState, isLoading: false }))
            }
         }

      getForumTopics()
   }, [authToken, reRender])

   // obtener los temas a los que el usuario tiene acceso
   const getDiscussionThemes = async () => {

      try {
         setState(prevState => ({ ...prevState, isLoading: true }))
         const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/users/discussionsThemes`
         const { data } = await axios.get(url, {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${authToken}`
            }
         })

         setDiscussionThemes(data);
      } catch (err) {
         console.log(err)

         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al cargar discusiones.",
            message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
        })
      } finally{
         setState(prevState => ({...prevState, isLoading: false}))
      }
   }

   const filterDiscussions = (data, isDecrecent) => {
      if (!Array.isArray(data)) return []

      return data.sort((a, b) => {
         const timestampA = new Date(a.creationDate).getTime()
         const timestampB = new Date(b.creationDate).getTime()

         if (!isDecrecent) return timestampA - timestampB;
         return timestampB - timestampA;
      });
   };

   const handleToggleFilter = () => {
      setIsDecrecent(prevState => !prevState)
   }

   const generalDiscussions = topics?.general
   const acaUnitsDiscussions = topics?.acaUnits

   const allDiscussionTopics = filterDiscussions(generalDiscussions?.concat(acaUnitsDiscussions), isDecrecent)

   return {
      state,
      isDecrecent,
      allDiscussionTopics,
      handleToggleFilter,
      getDiscussionThemes,
      isModal,
      setIsModal,
      setTopics,
      reRender,
      setReRender,
   }
}
