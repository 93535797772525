import { IconCat, IconDog } from '@tabler/icons-react'
import './SelectPreferedPet.css'

export default function SelectPreferedPet({setFieldValue, setAnimalType, animalType}) {

   return (
      <div className="RegisterPage-pet-container">
         <label className="RegisterPage-pet-text">¿Qué mascota preferís?</label>
         <div className="RegisterPage-pet-radios--container">
            <div
               className="RegisterPage-pet-radioGroup"
            >
               <input
                  type="radio"
                  className="pet-radio--input"
                  name="animalType"
                  value="dog"
                  checked={animalType === "dog"}
                  onClick={({target}) => {
                     setAnimalType(target.value)
                     setFieldValue('animalType', target.value)
                  }}
                  
               />
               <IconDog className="pet-radio--icon" />
            </div>
            <div
               className="RegisterPage-pet-radioGroup"
            >
               <input
                  type="radio"
                  className="pet-radio--input"
                  name="animalType"
                  value="cat"
                  checked={animalType === "cat"}
                  onClick={({target}) => {
                     setAnimalType(target.value)
                     setFieldValue('animalType', target.value)
                  }}
                  
               />
               <IconCat className="pet-radio--icon" />
            </div>
         </div>
      </div>
   )
}
