import React from "react";
import {Skeleton} from "@mantine/core";
import './CoursesSkeleton.css';

const CoursesSkeleton = () => {
    return (
        <div className="cousesSkeleton__wrapper">
            <Skeleton height={210}/>
            <section className="cousesSkeleton__container">
                {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} height={116} style={{marginBottom: 10}}/>
                ))}
            </section>
        </div>
    );
};

export default CoursesSkeleton;
