import React from "react";
import { Link } from "react-router-dom";
import "./SponsorBanner.css";
import { Image } from "@mantine/core";

const SponsorBanner = ({ link, img, title }) => {
   return (
      <>
         {link ? (
            <Link
               className="sponsorBanner-link-navigation"
               target="_blank"
               to={link}
            >
               <div className="sponsorBanner-container-div">
                  <Image className="sponsorBanner-image-custom" src={img} />
                  <div className="sponsorBanner-content-container">
                     <h2 className="sponsorBanner-title-for-sponsor">
                        {title}
                     </h2>
                  </div>
               </div>
            </Link>
         ) : (
            <div className="sponsorBanner-container-div">
               <Image className="sponsorBanner-image-custom" src={img} />
               <div className="sponsorBanner-content-container">
                  <h2 className="sponsorBanner-title-for-sponsor">{title}</h2>
               </div>
            </div>
         )
         }
      </>
   );
};

export default SponsorBanner;
