import { Splide, SplideSlide } from '@splidejs/react-splide'
import './OpportunitiesCarousel.css'
import OpportunitiesSlide from '../OpportunitiesSlide/OpportunitiesSlide'

export default function OpportunitiesCarousel({ handleClickCard, itemsToMap }) {
   return (
      <section className='OpportunitiesCarousel-container'>
         <Splide aria-label="Oportunidades destacadas" options={{
            rewind: true,
            height: '300px',
         }} >
            {itemsToMap.map(item => (

               <SplideSlide>
                   {/*<p>{item.logoUrl}</p>*/}
                  <OpportunitiesSlide
                     handleClickCard={handleClickCard}
                     title={item.title}
                     description={item.description}
                     redirectUrl={item.redirectUrl}
                     bannerImageUrl={item.bannerImageUrl}
                     logoUrl={item.logoUrl}
                  />
               </SplideSlide>
            ))}
         </Splide>
      </section>
   )
}
