import React, {useRef} from 'react';
import './ExamQuestion.css';
import {Radio} from '@mantine/core';

const getRadioClasses = (answer, trueAnswer) => {
    let rootClass = 'examQuestion__radio__root';
    let labelClass = 'examQuestion__radio__label';

    if (trueAnswer && trueAnswer === answer.value) {
        rootClass += ' examQuestion__radio__root--trueAnswer';
        labelClass += ' examQuestion__radio__label--trueAnswer';
    } else if (trueAnswer) {
        rootClass += ' examQuestion__radio__root--falseAnswer';
        labelClass += ' examQuestion__radio__label--trueAnswer';
    }

    return { root: rootClass, label: labelClass };
};

function ExamQuestion(props) {
    const {title, questionText, answers, currentAnswer, setCurrentAnswer, error, trueAnswer} = props;
    const radioRefs = useRef([]);

    const handleDivClick = (index) => {
        if (radioRefs.current[index]) {
            radioRefs.current[index].click();
        }
    };

    return (
        <div className="examQuestion__container">
            <div className="examQuestion__question">
                <h2 className="examQuestion__question__title">{title}</h2>
                <p className="examQuestion__question__text">{questionText}</p>
            </div>
            <div className="examQuestion__answers__container">
                <Radio.Group
                    classNames={{
                        root: 'examQuestion__radioGroup__root',
                    }}
                    value={currentAnswer}
                >
                    {answers.map((answer, index) => {
                        return (
                            <div className="examQuestion__answer" key={index}
                                 onClick={() => handleDivClick(index)}
                            >
                                <Radio
                                    ref={(ref) => (radioRefs.current[index] = ref)}
                                    value={answer.value}
                                    label={answer.label}
                                    disabled={trueAnswer}
                                    classNames={getRadioClasses(answer, trueAnswer)}
                                    onChange={(e) => {
                                        setCurrentAnswer(e.target.value)
                                    }}
                                />
                            </div>
                        )
                    })}
                </Radio.Group>
                {error && <p className="examQuestion__error">{error}</p>}
            </div>
        </div>
    );
}

export default ExamQuestion;