import React from 'react';
import './QuestionNavigator.css';

function QuestionNavigator(props) {
    const {questions, currentQuestion, setCurrentQuestion, setCurrentAnswer, handleNextQuestion} = props;
    return (
        <div className="questionNavigator__container">
            {questions.map((question, index) => {
                return (
                    <div key={index}
                         className={`questionNavigator__questionSelector ${index === currentQuestion ? 'questionNavigator__questionSelector--active' : ''}`}
                         onClick={() => {
                             setCurrentQuestion(index);
                             handleNextQuestion(index)
                             setCurrentAnswer(question.userAnswer);
                         }}>
                        {index + 1}
                    </div>
                );
            })
            }
        </div>
    );
}

export default QuestionNavigator;