import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {Skeleton} from '@mantine/core';

export default function DashboardSkeleton() {
    const {width} = useWindowDimensions();
    const isMobile = width <= 768;

    return (
        <div className='DashboardSkeleton-container mainLayout__container'>
            <div className='mainLayout__aside'>
                <div className='sidebar-container'>
                    < Skeleton className='sidebar-content' width='100%' height='100%'/>
                </div>
            </div>
            <div className='mainLayout__main'>
                <div className='dashboard__layout'>

                    < Skeleton className='dashboard__header' style={{gridArea: 'header'}} width='100%' height='100px'/>

                    {!isMobile && <div className="dashboard__calendar">
                        < Skeleton className='CalendarAside-container' height='100%' width='100%'/>
                    </div>
                    }

                    <div className='dashboard__main'>
                        < Skeleton className='activeCardCourse-father-div' height='200px' width='100%'/>
                        <div className='sponsor-products-container'>
                            <Skeleton height='200px' width={isMobile ? '100%' : '400px'} style={{marginTop: '24px'}}/>
                            < Skeleton className='dasboardRemindCard-father-div' height='200px' width='100%'/>
                        </div>
                        < Skeleton width='100%' height='300px' style={{marginTop: '24px'}}/>
                        < Skeleton width='100%' height='100%' style={{marginTop: '24px'}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
