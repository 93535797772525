import React, { useState, useEffect, useRef } from 'react';
import './LoginAnimationImage.css'
import gif1 from '../../../assets/LoginPage/animal_1.gif'
import gif2 from '../../../assets/LoginPage/animal_2.gif'
import gif3 from '../../../assets/LoginPage/animal_3.gif'
import gif4 from '../../../assets/LoginPage/animal_4.gif'
import gif5 from '../../../assets/LoginPage/animal_5.gif'

export default function LoginAnimationImage() {
   const [gifIndex, setGifIndex] = useState(0);
   const [isFadingOut, setIsFadingOut] = useState(false);

   const randomValueFromArray = (array) => {
      if (!array) return false
      const randomIndex = Math.floor(Math.random() * array.length);
      const randomValue = array[randomIndex];
      return randomValue
   }

   const GIF_OPTIONS = [
      { image: gif1, alt: 'gatito con puntero' },
      { image: gif2, alt: 'gatito en un pizarrón' },
      { image: gif3, alt: 'gatito señalando' },
      { image: gif4, alt: 'gatito leyendo' },
      { image: gif5, alt: 'gatito mirando por una lupa' },
   ];

   const gifToShow = useRef(randomValueFromArray(GIF_OPTIONS));

   useEffect(() => {
      const intervalId = setInterval(() => {
         setIsFadingOut(true);
         setTimeout(() => {
            setGifIndex(prevIndex => (prevIndex + 1) % GIF_OPTIONS.length);
            setIsFadingOut(false);
         }, 4000);
      }, 5000);

      return () => clearInterval(intervalId);
   }, []);

   useEffect(() => {
      gifToShow.current = GIF_OPTIONS[gifIndex];
   }, [gifIndex]);

   return (
       <div className='LoginPage-animation-container'>
          {gifToShow.current &&
          <div className={`LoginPage-gif-container ${isFadingOut ? 'fade-in' : 'fade-out'}`}>
             <img className='LoginPage--gif' src={gifToShow.current.image} alt={gifToShow.current.alt} />
          </div>
          }
       </div>
   );
}
