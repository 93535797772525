import { useLocation, useNavigate } from "react-router-dom";
import "./ValidateEmailFalse.css";
import useContactCard from "../../hooks/useContactCard";
import HelpContactCard from "../HelpContactCard/HelpContactCard";
import { Button } from "@mantine/core";

const ValidateEmailFalse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate ("/")
  };

  let data, description;
  if (message === "This URL can be used only once") {
    data = "Tu email ya ha sido verificado previamente";
    description = "¡Ingresa al campus con tu email y contraseña y comienza a vivir la experiencia FORVET!"
  } else {
    data =
      "Parece que hubo un inconveniente verificando tu email. Porfavor contáctanos.";
  }
  return (
    <div className="validateEmailFalse-father-div">
      <img
        className="validateEmailFalse-image"
        src="https://ugokawaii.com/wp-content/uploads/2023/07/thinking.gif"
        alt="validateEmail failed"
      />
      <h3 className="validateEmailFalse-title">{data}</h3>
      <h4 className="validateEmailFalse-text">Ya puedes cerrar esta pestaña.</h4>
      <p className="validateEmailFalse-secondaryText">{description}</p>
      <div className="validateEmailFalse-contactArea-style">
      <HelpContactCard />
      </div>
      <Button fw="normal" variant="outline" onClick={handleClick}>Ir al campus</Button>
    </div>
  );
};
export default ValidateEmailFalse;
