import { Button, Loader, Menu } from "@mantine/core";
import { IconChevronDown, IconDotsVertical, IconGavel, IconMessage } from "@tabler/icons-react";
import { NO_AUTHORIZATION_CODE } from "../../../utils/forum-rules";

export default function ForumHeaderButtonsMobile({ handleToggleFilter, isDecrecent, getDiscussionThemes, setIsModal, state }) {
   return (
      <div className='ForoPageHeader--mobile-container'>

         <Button color='var(--color-secondary)' onClick={handleToggleFilter} w={'max-content'} >
            < IconChevronDown className='ForoPageHeader-button--icon' />
            <span className='ForoPageHeader-button--text'>
               {isDecrecent ? 'Más recientes' : 'Más antiguos'}
            </span>
         </Button>

         <Menu shadow="md" width={200}>
            <Menu.Target>
               < IconDotsVertical size={30} />
            </Menu.Target>

            <Menu.Dropdown>

               <Menu.Label>Crear</Menu.Label>
               <Menu.Item leftSection={< IconMessage className='ForoPageHeader-button--icon' />} onClick={state.error.status === NO_AUTHORIZATION_CODE ? null : () => getDiscussionThemes()}>
                  <span className='ForoPageHeader-button--text' >
                     {state.isLoading ? < Loader size={20} color="var(--color-base-ligth)" /> : "Nueva discusión"}
                  </span>
               </Menu.Item>

               <Menu.Divider />

               <Menu.Label>Reglamento</Menu.Label>
               <Menu.Item leftSection={< IconGavel className='ForoPageHeader-button--icon' />} onClick={() => setIsModal(true)} >
                  <span className='ForoPageHeader-button--text'>
                     Reglas del foro
                  </span>
               </Menu.Item>

            </Menu.Dropdown>

         </Menu>
      </div>
   )
}
