import ValidateEmailHandler from "../../features/ValidateEmailHandler/ValidateEmailHandler";
import './ValidateEmail.css';
const ValidateEmail = () => {
  return (
    <>
      <div className="validateEmail-layout">
        <ValidateEmailHandler />
      </div>
    </>
  );
};
export default ValidateEmail;
