import React, { useContext, useEffect } from "react";
import Axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import DashboardSkeleton from "../../features/Dashboard/DashboardSkeleton/DashboardSkeleton";
import AuthContext from "../../contexts/AuthContext";
import showNotification from "../../utils/showNotification";
import { ERROR_HANDLER } from "../../utils/error-handler";

export default function Callback() {
  const { isAuthenticated, user } = useAuth0();
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const disabledTrue = "Usuario deshabilitado por la administración de ForVet, contáctanos para más información."

  useEffect(() => {
    const validateUser = () => {
      Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/login`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      })
        .then(({ data }) => {
          if (data.complete) {
            if (data.user.disabled === false) {
              return navigate("/campus/dashboard");
            } else {
              return navigate(`/?message=${disabledTrue}` );
            }
          } else {
            return navigate("/campus/registro");
          }
        })

        .catch((err) => {
          console.error(err);
          if (err.request.status === 400) {
            return navigate("/campus/registro");
          }
          showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al iniciar sesión.",
            message: `Detalle: ${
              ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError
            }`,
            autoClose: 12000,
          });
          return navigate("/");
        });
    };

    if (isAuthenticated) {
      validateUser();
    }
  }, [user, isAuthenticated, navigate]);

  return <DashboardSkeleton />;
}
