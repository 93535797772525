import './RateFaceButton.css'
import regularFace from '../../../../assets/RateClassBanner/regular_face.svg'
import sadFace from '../../../../assets/RateClassBanner/sad_face.svg'
import smileFace from '../../../../assets/RateClassBanner/smile_face.svg'
import { RATE_VALUES } from '../../../../utils/rate-values'

export default function RateFaceButton({ icon = 'smile', isChecked, label = null, noImage = null, children, ...others }) {

   const renderImage = () => {
      switch (icon) {
         case RATE_VALUES.good:
            return < img src={smileFace} className='RateFaceButton--image' alt='buena puntuación' />
         case RATE_VALUES.regular:
            return < img src={regularFace} className='RateFaceButton--image' alt='puntuación regular' />
         case RATE_VALUES.bad:
            return < img src={sadFace} className='RateFaceButton--image' alt='mala puntuación' />
         default:
            return < img src={regularFace} className='RateFaceButton--image' alt='mala puntuación' />
      }
   }

   return (
      <div className={`RateFaceButton-container ${label ? 'RateFaceButton--column' : ''}`} {...others}>
         < input className='RateFaceButton--input' type='radio' name='rate' value={icon} checked={isChecked} />
         { !noImage && renderImage() }
         { children }
         { label && <label className='RateFaceButton--label'>{label}</label> }
      </div>
   )
}
