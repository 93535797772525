import React, {useContext} from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./Dashboard.css";
import ActiveCourseCard from "../../features/Dashboard/CourseCard/ActiveCourseCard";
import DailyRecommendationCard from "../../features/Dashboard/DailyRecommendationCard/DailyRecommendationCard";
import CalendarAside from "../../components/CalendarAside/CalendarAside";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AppContext from "../../contexts/AppContext";
import ReminderAndSponsors from "../../features/Dashboard/ReminderAndSponsors/ReminderAndSponsors";

function Dashboard() {
    const {currentUser, isCalendarCollapsed} = useContext(AppContext);
    const {width} = useWindowDimensions();
    const isTablet = width < 1000;
    return (
        <MainLayout>
            <div
                className={`${
                    isCalendarCollapsed
                        ? "dashboard__layout--collapsed"
                        : "dashboard__layout"
                }`}
            >
                <div className="dashboard__header">
                    <p className="dashboard__header__userName">¡Hola, {currentUser.name}!</p>
                </div>
                {(!isTablet) && (
                    <div className="dashboard__calendar">
                        <CalendarAside/>
                    </div>
                )}
                <div className="dashboard__main">
                    <ActiveCourseCard/>
                    <ReminderAndSponsors/>
                    <DailyRecommendationCard/>
                </div>
            </div>
        </MainLayout>
    );
}

export default Dashboard;
