import { Modal } from "@mantine/core";

const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      classNames={{ title: "termsAndConditionsModal-title" }}
      opened={isOpen}
      onClose={onClose}
      title="Términos y condiciones del Campus de FORVET."
    >
      <p className="termsAndConditionsModal-text">
        Bienvenido/a al campus virtual de FORVET. Antes de continuar, por favor,
        toma un momento para revisar nuestros términos y condiciones de uso.
        <hr></hr>
        Al acceder y utilizar nuestro campus virtual, aceptas cumplir con estos
        términos. Si no estás de acuerdo con alguna parte de estos términos, te
        solicitamos que no continúes utilizando nuestro servicio.
        <hr></hr>
        Información del Usuario: 
        Al registrarte en nuestro campus virtual, proporcionas
        información personal como tu nombre, apellido, dirección de correo
        electrónico, nacionalidad, número de teléfono, género y fecha de
        nacimiento. Esta información será tratada de manera confidencial y no
        será compartida con terceros sin tu consentimiento explícito.
        <hr></hr>
        Confidencialidad: Tu privacidad es de suma importancia para nosotros.
        Toda la información proporcionada será utilizada únicamente con fines
        internos y educativos. Nos comprometemos a no divulgar, vender o
        transferir tu información personal a terceros sin tu consentimiento,
        excepto cuando sea requerido por la ley.
        <hr></hr>
        Uso Responsable: Al acceder al campus virtual, te comprometes a utilizar los servicios de manera
        responsable y ética. No está permitido el uso indebido, la manipulación,
        o la violación de la privacidad de otros usuarios.
        <hr></hr>
        Derechos de Propiedad Intelectual: Todos los contenidos, materiales y recursos proporcionados
        en el campus virtual son propiedad exclusiva de FORVET o de sus
        respectivos propietarios, y están protegidos por derechos de propiedad
        intelectual. No se permite la reproducción, distribución o modificación
        no autorizada de estos contenidos. 
        <hr></hr>
        Cambios en los Términos y Condiciones: Nos reservamos el derecho de actualizar o modificar estos
        términos y condiciones en cualquier momento. Te recomendamos revisar
        periódicamente esta sección para estar al tanto de cualquier cambio.
        <hr></hr>
        Normas de Convivencia en el Foro: Dentro de nuestro campus virtual,
        ofrecemos un foro como un espacio para el intercambio de ideas,
        discusiones y colaboración entre los usuarios. Para garantizar una
        experiencia positiva para todos, te pedimos que sigas estas normas de
        convivencia: 
        <hr></hr>
        1. Respeto: Trata a todos los usuarios con respeto y
        cortesía. Evita cualquier forma de lenguaje ofensivo, discriminación,
        acoso o intimidación. 
        <hr></hr>
        2. Contenido Apropiado: No publiques material que
        sea difamatorio, obsceno, amenazante, ilegal o que viole los derechos de
        propiedad intelectual de terceros.
        <hr></hr>
        3. Colaboración: Fomenta un ambiente
        colaborativo. Comparte conocimientos, experiencias y recursos de manera
        constructiva. 
        <hr></hr>
        4. Moderación: Respeta las indicaciones y decisiones de
        los moderadores del foro. Su objetivo es mantener un ambiente seguro y
        respetuoso. 
        <hr></hr>
        5. Publicidad No Autorizada: No realices publicidad no
        autorizada. Cualquier contenido promocional debe ser aprobado
        previamente por FORVET. 
        <hr></hr>
        Incumplir estas normas puede resultar en
        acciones disciplinarias, que incluyen advertencias, restricciones de
        acceso al foro o la eliminación de contenido inapropiado. La gravedad de
        la sanción dependerá de la naturaleza y repetición de la infracción.
        <hr></hr>
        Aceptación de Normas de Convivencia en el Foro: Al participar en el foro
        dentro de nuestro campus virtual, aceptas cumplir con estas normas de
        convivencia. La violación reiterada de estas normas puede resultar en la
        terminación de tu cuenta y acceso al campus virtual. Agradecemos tu
        compromiso con un ambiente educativo positivo y colaborativo. FORVET.
      </p>
    </Modal>
  );
};

export default TermsAndConditionsModal;
