import React from 'react';
import { Skeleton } from '@mantine/core';
import './HelpSkeleton.css';

const HelpSkeleton = () => {
  return (
    <div className="help-skeleton">
      <Skeleton height={20} style={{ marginBottom: 16 }} />
      <Skeleton height={100} count={5} style={{ marginBottom: 16 }} />
    </div>
  );
};

export default HelpSkeleton;
