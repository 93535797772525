import './MaterialsTable.css';
import { Table, ScrollArea } from '@mantine/core';
import { IconDownload, IconFileTypePdf, IconFileTypeDoc, IconFileText, IconFileTypeZip, IconExternalLink, IconBrandYoutube } from '@tabler/icons-react';
import { openUrl } from "../../../utils/OpenUrl";

const typesDict = {
   pdf: <IconFileTypePdf />,
   doc: <IconFileTypeDoc />,
   zip: <IconFileTypeZip />,
   youtube: <IconBrandYoutube />,
}

const getIcon = (type) => {
   return typesDict[type?.toLowerCase()] || <IconFileText />
}

function getDownloadLink(action, link) {
   if (link.includes("&dl=0")) {
      if (action === "download")
         link = link.replace("&dl=0", "&dl=1");
      if (action === "open")
         link = link.replace("&dl=0", "&raw=1");
   } else {
      link += "&raw=1";
   }
   return link;
}

const handleDownload = async (action, url) => {
   openUrl(getDownloadLink(action, url));
}

const renderIcon = (item) => {
   if (item.type === 'YOUTUBE') {
      return (
          <IconExternalLink className='materialsTable__icon' onClick={() => openUrl(item.link)} />
      );
   } else {
      return (
          <>
             <IconExternalLink className='materialsTable__icon' onClick={() => handleDownload("open", item.link)} />
             <IconDownload className='materialsTable__icon' onClick={() => handleDownload("download", item.link)} />
          </>
      );
   }
}

export function MaterialsTable(props) {
   const { data, height = '600px', tableTitle } = props;

   const rows = data?.map((item) => {
      return (
         <Table.Tr
            key={item._id}
            className='materialsTable__row-container'
         >
            <Table.Td className='materialsTable--max-width'>
               <div className='materialsTable__cell'>{getIcon(item.type)}</div>
            </Table.Td>
            <Table.Td className='materialsTable--fileName'>
               {item.name || item.materialName}
            </Table.Td>
            <Table.Td>
               <div className='materialsTable__cell'>
                  {renderIcon(item)}
               </div>
            </Table.Td>
         </Table.Tr>
      );
   });
   return (
      <div className="materialTable__container">
         <ScrollArea style={{ width: '100%' }} h={height} scrollbarSize={6}>
            <Table verticalSpacing="md" highlightOnHover style={{ width: '100%' }}>
               <Table.Thead>
                  {tableTitle &&
                     <Table.Tr>
                        <Table.Th colSpan={'100%'}>{tableTitle}</Table.Th>
                     </Table.Tr>}
                  <Table.Tr>
                     {data?.length === 0
                        ? <Table.Th>No hay materiales disponibles</Table.Th>
                        : <>
                           <Table.Th className='materialsTable--max-width'>Tipo</Table.Th>
                           <Table.Th>Nombre</Table.Th>
                           <Table.Th className='materialsTable-header--actions'>Acciones</Table.Th>
                        </>
                     }
                  </Table.Tr>
               </Table.Thead>
               <Table.Tbody>{rows}</Table.Tbody>
            </Table>
         </ScrollArea>
      </div>
   );
}