import './ForumPageHeader.css'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ForumHeaderButtonsMobile from '../ForumHeaderButtonsMobile/ForumHeaderButtonsMobile';
import ForumHeaderButtonsDesktop from '../ForumHeaderButtonsDesktop/ForumHeaderButtonsDesktop';
import {useState} from 'react';
import ForumRulesModal from '../ForumRulesModal/ForumRulesModal';

export default function ForumPageHeader({handleToggleFilter, isDecrecent, getDiscussionThemes, state}) {
    const {width} = useWindowDimensions();
    const isTablet = width <= 1000;

    const [isModal, setIsModal] = useState()

    return (
        <header className='ForoPageHeader-container'>
            <h1 className="ForoPageHeader-title">Foro FORVET</h1>

            {isTablet
                ? < ForumHeaderButtonsMobile
                    handleToggleFilter={handleToggleFilter}
                    isDecrecent={isDecrecent}
                    getDiscussionThemes={getDiscussionThemes}
                    setIsModal={setIsModal}
                    state={state}
                />

                : < ForumHeaderButtonsDesktop
                    handleToggleFilter={handleToggleFilter}
                    isDecrecent={isDecrecent}
                    getDiscussionThemes={getDiscussionThemes}
                    setIsModal={setIsModal}
                    state={state}
                />}

            {isModal && < ForumRulesModal isModal={isModal} setIsModal={setIsModal}/>}

        </header>
    )
}
