import { Button } from '@mantine/core'
import './LoginSlide.css'

export default function LoginSlide({ name, description, id }) {
   return (
      <article className='LoginSlide-container'>
         <h3 className='LoginSlide-title'>{name}</h3>
         <p className='LoginSlide-description'>{description}</p>
         <Button 
            variant='outline' 
            radius='md' 
            w='max-content' 
            color='var(--color-primary)' 
            m={{base: 'auto', sm: 'unset'}} 
            component='a'
            href={`https://forvet.org/curso/${id}`}
            target='_blank'
            >
               Ver más
            </Button>
      </article>
   )
}
