import { useContext, useState } from 'react'
import AppContext from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from '@mantine/form';
import { isBase64 } from '../utils/isBase64';
import axios from 'axios';
import { USER_DEFAULT_VALUES } from '../utils/user-default-values';
import AuthContext from '../contexts/AuthContext';
import showNotification from '../utils/showNotification';
import { ERROR_HANDLER } from '../utils/error-handler';
import {passwordStrength} from "check-password-strength";

export default function useProfileForm(initialValues, validations, passwordRequired) {
   
   const { user } = useAuth0()
   const { authToken } = useContext(AuthContext)
   const { currentUser } = useContext(AppContext);
   const [state, setState] = useState({ isLoading: false, error: null })
   const navigate = useNavigate()

   const form = useForm({
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
         ...initialValues,
         profilePicture: currentUser?.profilePicture ?? user?.picture ?? USER_DEFAULT_VALUES.profilePicture,
      },
      validate: { ...validations }
   })

   const [animalType, setAnimalType] = useState(currentUser?.animalType)
   const [profilePicture, setProfilePicture] = useState(form.values.profilePicture)


   const handleFileChange = (event) => {
      const ALLOWED_FILES_TYPES = ['image/png', 'image/jpeg']
      const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024 // 5 mb
      const file = event.target.files[0] // Obtener el primer archivo seleccionado

      if (file) {
         if (file.size > MAX_SIZE_IN_BYTES) {
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un error al intentar subir la imagen.",
               message: `Detalle: el archivo es muy grande. El tamaño máximo permitido es de 5mb.`,
               autoClose: 12000
            })
            return setState({...state, error: 'Lo siento, el archivo es muy grande.'})
         }
         if (!ALLOWED_FILES_TYPES.includes(file.type)) return setState({ ...state, error: 'Lo siento, el archivo no es soportado.' })
         const reader = new FileReader()
         reader.onloadend = () => {
            setProfilePicture(reader.result)
            form.setFieldValue('profilePicture', reader.result)
         }
         reader.readAsDataURL(file)
      }
   }

   const handleSubmit = async (event) => {
      event.preventDefault()

      const isValid = Object.entries(form.errors).length === 0

      if (!isValid) return setState(prevState => ({ prevState, error: 'Lo siento, hay campos inválidos o vacíos.' }))

      if (animalType !== 'dog' && animalType !== 'cat') {
         return setState(prevState => ({ ...prevState, error: 'Lo siento, debes elegir un gato o un perro.' }))
      }

      if(passwordRequired && !form.values.firstPassword){
         return setState(prevState => ({ ...prevState, error: 'Por favor, por tu seguridad ingresa una nueva contraseña.' }))
      }

      if (passwordRequired && form.values.firstPassword) {
         const strength = passwordStrength(form.values.firstPassword);
         if (strength.id < 3) {
            return setState(prevState => ({ ...prevState, error: 'La contraseña ingresada no es lo suficientemente segura.' }));
         }
      }
   

      setState(prevState => ({ ...prevState, isLoading: true }))

      try {
         // separa items
         const { firstPassword, confirmPassword, description, personalItems, ...restOfValues } = form.values
         // usa la estructura del esquema de usuario
         let postData = {
            ...restOfValues,
            about: {
               description,
               personalItems
            }
         }

         let url_AWS_image = ''
         if (isBase64(profilePicture)) {
            const directory = "public/images/users";
            const name = `${currentUser.name}_${currentUser.lastname}.jpeg`

            if (!currentUser || !currentUser.name || !currentUser.lastname) {
               throw new Error('No se ha podido obtener el nombre y apellido del usuario.')
            }

            // Convertir la imagen base64 a un Blob directamente en la peticion
            const blob = await fetch(profilePicture).then(res => res.blob())

            const formData = new FormData()
            formData.append('directory', directory)
            formData.append('name', name)
            formData.append('file', blob)

            const aws_response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}admin/uploadToS3`, formData, {
               headers: {
                  'Content-Type': 'multipart/form-data',
                  "Authorization": "Bearer " + authToken
               },
            });

            url_AWS_image = aws_response.data.url
            postData = { ...postData, profilePicture: url_AWS_image }
         }

         // en caso de que la contraseña sea valida
         if(firstPassword){
            const data =  {
               email: currentUser.email,
               newPassword: form.values.firstPassword
            }
            await axios.put(`${process.env.REACT_APP_BACKEND_SERVER}campus/users/changePass`, { data }, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })
         }

         await axios.put(`${process.env.REACT_APP_BACKEND_SERVER}campus/users`, { data: postData }, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": "Bearer " + authToken,
            }
         })

         // setCurrentUser(response.data)
         showNotification({
            color: "green",
            status: "success",
            title: "Tu usuario ha sido actualizado correctamente",
            message: `Detalle: Si los cambios no se reflejan automáticamente, recarga la página.`
        })
         navigate('/campus/dashboard')

      } catch (err) {
         console.error(err)
         setState({ ...state, error: 'Lo siento, ha ocurrido un error' })
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema enviar los datos y no se pudo actualizar tu usuario.",
            message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
        })
      } finally {
         setState(prevState => ({ ...prevState, isLoading: false }))
      }
   }

   return {
      form,
      animalType,
      profilePicture,
      setAnimalType,
      handleFileChange,
      handleSubmit,
      state,
   };
}
