import './OpportunitiesCard.css'

export default function OpportunitiesCard({ title = 'medify', description = 'Praesent luctus nisl nec nisl lobortis nec sollicitudin', redirectUrl, logoUrl, handleClickCard, bannerImageUrl }) {


   return (
      <article className='OpportunitiesCard-container'>
         <figure className='OpportunitiesCard-logo-container'>
            < img className='OpportunitiesCard--logo' src={logoUrl} alt={`foto de ${title}`} />
         </figure>

         <div className='OpportunitiesCard-texts-container'>
            <h4 className='OpportunitiesCard--title'>{title}</h4>
            <p className='OpportunitiesCard--description'>{description}</p>

            
            <button className='OpportunitiesCard--link' onClick={() => handleClickCard({title, description, redirectUrl, logoUrl, bannerImageUrl})}>
               Conocer más
            </button>
         </div>
      </article>
   )
}
