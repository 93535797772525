import CalendarRow from '../CalendarRow/CalendarRow'
import './CalendarItem.css'

export default function CalendarItem({ events, month }) {

   return (
      <div className='CalendarItem-container'>
         <h5 className='CalendarItem--month'>{month}</h5>
         <div className='CalendarItem-items--container'>
            {events.map(item => {
               const date = new Date(item.initDate);
               const day = date.toLocaleString('es-ES', { day: 'numeric' });
               return (
                  < CalendarRow key={item._id} day={day} name={item.name} event={item.event} type={item.type} auName={item.auName}  />
               )
            })}
         </div>
      </div>
   )
}
