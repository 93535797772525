import React, {useContext, useState} from "react";
import "./AdvancedQueryForm.css";
import {Select, TextInput, Button} from "@mantine/core";
import axios from "axios";
import showNotification from "../../utils/showNotification";
import AuthContext from "../../contexts/AuthContext";
import {ERROR_HANDLER} from "../../utils/error-handler";
import HelpContactCard from "../HelpContactCard/HelpContactCard";

const AdvancedQueryForm = () => {
    const [problemType, setProblemType] = useState("");
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const {authToken} = useContext(AuthContext);

    const handleProblemTypeChange = (value) => {
        setProblemType(value);
    };

    const handleQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}campus/support`,
                {
                    data: {
                        issueType: problemType,
                        description: query,
                    },
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authToken,
                    },
                }
            );
            showNotification({
                color: "green",
                status: "success",
                title:
                    "¡Tu consulta ha sido enviada! Te mantendremos informado sobre la resolución de tu consulta.",
            });
        } catch (error) {
            console.error("Error posting new advanced question data:", error);
            showNotification({
                color: "red",
                status: "error",
                title: "Ha ocurrido un problema enviar los datos y no podido actualizar tu usuario.",
                message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2 className="advancedQueryForm-headerTitle">
                ¿No encontraste lo que buscabas?
            </h2>
            <h4 className="advancedQueryForm-headerSubTitle">
                Ingrese su consulta a nuestro formulario de consultas avanzadas
            </h4>
            <div className="advancedQueryForm-formDiv">
                <Select
                    label="Tipo de problema"
                    placeholder="Seleccionar tipo"
                    data={[
                        {label: "Seccion/es del campus sin funcionar", value: "Servicio"},
                        {
                            label: "No encuentro mi curso/ He perdido informacion personal",
                            value: "Datos",
                        },
                        {label: "Inconvenientes en el pago", value: "Pagos"},
                        {
                            label: "Tengo problemas para acceder a mi cuenta",
                            value: "Acceso",
                        },
                        {label: "Consultas generales", value: "Generales"},
                        {
                            label: "Tengo un problema con un espacio puntual del campus",
                            value: "Funcionalidad",
                        },
                        {
                            label: "Necesito ayuda para manejarme en el campus",
                            value: "Capacitacion",
                        },
                    ]}
                    value={problemType}
                    onChange={handleProblemTypeChange}
                />
                <TextInput
                    label="Consulta"
                    placeholder="Escriba su consulta aquí..."
                    value={query}
                    onChange={handleQueryChange}
                    multiline
                />
                <Button
                    fw="normal"
                    classNames={{root: "advancedQueryForm-button-root"}}
                    loading={loading}
                    onClick={handleSubmit}
                >
                    Enviar consulta
                </Button>
            </div>
            <HelpContactCard/>
        </div>
    );
};

export default AdvancedQueryForm;
