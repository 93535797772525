import {useContext, useState} from 'react'
import './ForumTermsAndConditionsModal.css'
import {Button, Group, Loader, Modal} from '@mantine/core'
import axios from 'axios'
import AuthContext from '../../../contexts/AuthContext'
import showNotification from '../../../utils/showNotification'
import {ERROR_HANDLER} from '../../../utils/error-handler'

export default function ForumTermsAndConditionsModal({isModal, setIsModal}) {

    const {authToken} = useContext(AuthContext)
    const [state, setState] = useState({isLoading: false, error: null})

    const postTerms = async () => {
        try {
            setState({error: null, isLoading: true})
            const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/users/acceptTyC`
            await axios.put(url, {data: true}, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`
                }
            })

            setIsModal(false)
            window.location.reload(true)
        } catch (err) {
            showNotification({
                color: "red",
                status: "error",
                title: "Ha ocurrido un problema al enviar la informacion.",
                message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
            setState(prevState => ({
                ...prevState,
                error: 'Lo siento, ha ocurrido un error al intentar enviar la información. Intenta nuevamente.'
            }))
        } finally {
            setState(prevState => ({...prevState, isLoading: false}))
        }
    }

    return (
        <Modal
            classNames={{title: "termsAndConditionsModal-title"}}
            opened={isModal}
            onClose={() => setIsModal(false)}
            title="Términos y condiciones del foro de FORVET."
            withCloseButton={false}
            closeOnEscape={false}
            closeOnClickOutside={false}
        >
            <p className="termsAndConditionsModal-text">
                Bienvenido/a al foro de FORVET.
                <hr></hr>
                Antes de continuar, por favor,
                toma un momento para revisar nuestros términos y condiciones de uso.

                <hr></hr>
                <strong>1- Uso del Servicio:</strong>
                Al acceder y utilizar nuestro foro, aceptas cumplir con estos
                términos. Si no estás de acuerdo con alguna parte de estos términos, te
                solicitamos que no continúes utilizando nuestro servicio.
                <hr></hr>
                <strong>2- Propiedad Intelectual: </strong>Todo el contenido publicado en FORVET, incluidos textos,
                imágenes, videos y otros materiales, está protegido por derechos de autor y otros derechos de propiedad
                intelectual. Usted se compromete a respetar estos derechos y a no infringirlos de ninguna manera.
                <hr></hr>
                <strong>3- Responsabilidad del Usuario:</strong> Usted es responsable de todo el contenido que publique
                en FORVET y se compromete a no publicar ningún material que sea ilegal, difamatorio, obsceno, ofensivo o
                que viole los derechos de terceros.
                <hr></hr>
                <strong>4- Modificaciones del Servicio:</strong> FORVET se reserva el derecho de modificar, suspender o
                cancelar el servicio en cualquier momento y sin previo aviso. Esto puede incluir cambios en la
                funcionalidad, el diseño o cualquier otro aspecto de la plataforma.
                <hr></hr>
                <strong>5- Privacidad:</strong>FORVET recopila y utiliza cierta información personal de los usuarios de
                acuerdo con su política de privacidad. Al utilizar el servicio, usted acepta el tratamiento de sus datos
                personales de acuerdo con esta política.

            </p>
            <hr></hr>

            {state.error &&

            <p style={{
                fontSize: 'var(--fs-body)',
                color: 'var(--color-red)',
                textAlign: 'center',
                margin: '10px 0'
            }}>{state.error}</p>
            }

            <Group>
                <Button w='100%' color='var(--color-primary)' onClick={() => postTerms()}
                        disabled={state.isLoading}>{state.isLoading ?
                    <Loader color={"#fff"} size={20}/> : "Aceptar"}</Button>
                <Button variant='light' w='100%' onClick={() => setIsModal(false)}
                        disabled={state.isLoading}>Cancelar</Button>
            </Group>
        </Modal>
    )
}
