import "./ValidatedCertificate.css";
import { IconCalendarStats } from "@tabler/icons-react";
import { Accordion } from "@mantine/core";
import { useEffect, useState } from "react";
import axios from "axios";
import certification from "../../assets/Certificate/Certification.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useParams } from "react-router-dom";
import DecorativeEmptyCircle from "../../components/DecorativeEmptyCircle/DecorativeEmptyCircle";
import footer from "../../assets/Certificate/forvet_logo_footer.png";
import ValidateCertificateSkeleton from "./ValidateCertificateSkeleton";
import showNotification from "../../utils/showNotification";
import { ERROR_HANDLER } from "../../utils/error-handler";

const ValidatedCertificate = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const { userId, acaUnitId } = useParams();
  const [validateCertification, setValidateCertification] = useState();

  /* It doesn´t use headers auth or token, it´s a free route where everyone with the correct url can enter. */
  const fetchValidatedCertification = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}info/certificates/validate/${userId}/${acaUnitId}`
      );
      setValidateCertification(response.data);
    } catch (error) {
      console.error("Error feetching validated certificate data:", error);
      showNotification({
        color: "red",
        status: "error",
        title: "Ha ocurrido un problema obtener las validaciones del curso.",
        message: `Detalle: ${
          ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
        }`,
        autoClose: 12000,
      });
    }
  };

  useEffect(() => {
    fetchValidatedCertification();
  }, []);

  if (!validateCertification) {
    return (
      <div>
        <ValidateCertificateSkeleton />
      </div>
    );
  }
  return (
    <div className="validateCertificate-wrapper">
      <div className="validateCertificate-layout">
        <DecorativeEmptyCircle
          size={400}
          opacity={0.1}
          top={140}
          left={1180}
          color="#77BDE0"
          borderWidth={32}
        />
        <DecorativeEmptyCircle
          size={180}
          opacity={0.1}
          top={200}
          left={100}
          color="#77BDE0"
          borderWidth={32}
        />
        <DecorativeEmptyCircle
          size={100}
          opacity={0.1}
          top={100}
          left={610}
          color="#77BDE0"
          borderWidth={24}
        />
        <DecorativeEmptyCircle
          size={180}
          opacity={0.1}
          top={500}
          left={100}
          color="#77BDE0"
          borderWidth={16}
        />
        <DecorativeEmptyCircle
          size={200}
          opacity={0.1}
          top={690}
          left={980}
          color="#77BDE0"
          borderWidth={32}
        />
        <h2 className="validatedCertificate-title">
          {validateCertification.au.name}
        </h2>
        <div className="validatedCertificate-content-div">
          <div className="validateCertificate-accordion">
            <h3 className="validateCertificate-accordion-header">
              Programa del curso:
            </h3>
            <Accordion
              classNames={{
                root: "validateCertificate-accordion-root",
                content: "validateCertificate-accordion-content",
              }}
              variant="separated"
              transitionDuration={1000}
            >
              {validateCertification.au.program.map((item) => (
                <Accordion.Item key={item._id} value={item.name}>
                  <Accordion.Control icon={<IconCalendarStats />}>
                    <h4 className="validateCertificate-accordion-title">
                      {item.name}
                    </h4>
                  </Accordion.Control>
                  <Accordion.Panel>{item.description}</Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="validatedCertificate-topics-div">
            <h4 className="validatedCertificate-topics-titles">Alumno:</h4>
            <p className="validateCertificate-topics-content">
              {validateCertification.user.name}{" "}
              {validateCertification.user.lastname}
            </p>
            <h4 className="validatedCertificate-topics-titles">
              Modalidad Académica:
            </h4>
            <p className="validateCertificate-topics-content">
              {validateCertification.au.type}
            </p>
            <h4 className="validatedCertificate-topics-titles">Duración:</h4>
            <p className="validateCertificate-topics-content">
              {validateCertification.au.duration} hs
            </p>
            <h4 className="validatedCertificate-topics-titles">
              Calificación:
            </h4>
            <p className="validateCertificate-topics-content">
              {validateCertification.user.note || "No corresponde."}
            </p>
            {validateCertification.coordinatorName && (
              <>
                <h4 className="validatedCertificate-topics-titles">
                  Coordinador Académico:
                </h4>
                <p className="validateCertificate-topics-content">
                  {validateCertification.coordinatorName}
                </p>
              </>
            )}
            {validateCertification.directorName && (
              <>
                <h4 className="validatedCertificate-topics-titles">
                  Director:
                </h4>
                <p className="validateCertificate-topics-content">
                  {validateCertification.directorName}
                </p>
              </>
            )}
            {!isMobile && (
              <img
                className="validateCertificate-forvetCertified-img"
                src={certification}
                alt="Certified Badge"
              />
            )}
          </div>
        </div>
        <div className="validateCertificate-footer-div">
          <img
            className="validateCertificate-footer-img"
            src={footer}
            alt="validacion"
          />
        </div>
      </div>
    </div>
  );
};
export default ValidatedCertificate;
