import axios from "axios";
import "./Certificates.css";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import CertificatesCard from "../../features/Certificates/CertificatesCard/CertificatesCard";
import GetCertificateModal from "../../features/Certificates/GetCertificateModal/GetCertificateModal";
import SpiderGif from "../../assets/Certificate/spiderNoCertificates.gif";
import SproutGif from "../../assets/Certificate/sproutGrowingNoCertificates.gif";
import { Loader } from "@mantine/core";
import showNotification from "../../utils/showNotification";
import { ERROR_HANDLER } from "../../utils/error-handler";

const Certificates = () => {
    const [certificates, setCertificates] = useState([]);
    const {authToken} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [certificateInfo, setCertificateInfo] = useState(null);
    const [reFetch, setReFetch] = useState(false)

    const fetchCertificates = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}campus/users/certificates`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authToken,
                    },
                }
            );
            setCertificates(response.data);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Ha ocurrido un problema al cargar tus certificados.",
                message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            })
            console.error("Error fetching certificates data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCertificates();
    }, [reFetch]);

    const handleDownloadClick = (academicUnitId, isDownloadable, note) => {
        const hasAllDownloadOptions = note !== null;
        setCertificateInfo({academicUnitId, isDownloadable, hasAllDownloadOptions});
    };

    return (
        <MainLayout>
            <div className="certificates-layout">
                <h2 className="certificates-title">Mis Certificados</h2>
                <h3 className="certificates-subtitle">Certificados Disponibles</h3>
                {loading ? (
                    <div className="loader-container">
                        <Loader size="xl" color="var(--color-secondary-light)"/>
                    </div>
                ) : !certificates || certificates.length === 0 ? (
                    <div className="certificates-noCertificates-div">
                        <div className="certificates-noCertificates-textDiv">
                            <h4 className="certificates-noCertificates-header">
                                Parece que aún no tienes certificados disponibles!
                            </h4>
                            <p className="certificates-noCertificates-textContent">
                                ¡Continúa creciendo en tu camino para limpiar un poco este polvo!
                            </p>
                            <img
                                className="certificates-noCertificates-sproutImage"
                                src={SproutGif}
                                alt="Sprout Gif"
                            />
                        </div>
                        <img
                            className="certificates-noCertificates-image"
                            src={SpiderGif}
                            alt="Spider Gif"
                        />
                    </div>
                ) : (
                    <>
                        <CertificatesCard
                            certificates={certificates}
                            handleDownloadClick={handleDownloadClick}
                            setIsModal={setIsModal}
                        />

                        <GetCertificateModal
                            isModal={isModal}
                            setIsModal={setIsModal}
                            acaUnit_id={certificateInfo?.academicUnitId}
                            isDownloadable={certificateInfo?.isDownloadable}
                            hasAllDownloadOptions={certificateInfo?.hasAllDownloadOptions}
                            setReFetch={setReFetch}
                        />
                    </>
                )}
            </div>
        </MainLayout>
    );
};
export default Certificates;
