import './GetCertificateModal.css'

import { Modal, ScrollArea } from '@mantine/core'
import CertificateSurvey from '../CertificateSurvey/CertificateSurvey'
import CertificateOptions from '../CertificateOptions/CertificateOptions'
import { QUESTIONS } from '../../../utils/downloadExam-survey-questions'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useState } from 'react'


export default function GetCertificateModal(
   {
      isDownloadable,
      isModal,
      setIsModal,
      acaUnit_id,
      hasAllDownloadOptions,
      setReFetch
   }) {

   const { width } = useWindowDimensions();
   const isTablet = width < 1000;

   // state para el componente CertificateSurvey
   const [state, setState] = useState({
      isLoading: false,
      error: null,
      success: {
         href: null,
         message: null
      }
   })

   const modalStyles = {
      body: {
         padding: 0,
      },
   }

   return (
      <Modal
         styles={modalStyles}
         size="auto"
         overlayProps={{
            backgroundOpacity: 0,
         }}
         scrollAreaComponent={ScrollArea.Autosize}
         fullScreen={isTablet}
         opened={isModal}
         onClose={() => setIsModal(false)}
         title={false}
         closeOnEscape={!state.isLoading}
         closeOnClickOutside={!state.isLoading}
         withCloseButton={false}
      >
         {!isDownloadable &&
            < CertificateSurvey
               questions={QUESTIONS}
               acaUnit_id={acaUnit_id}
               setIsModal={setIsModal}
               setReFetch={setReFetch}
            />}

         {isDownloadable &&
            <CertificateOptions
               setIsModal={setIsModal}
               acaUnit_id={acaUnit_id}
               state={state}
               setState={setState}
               hasAllDownloadOptions={hasAllDownloadOptions}
            />}
      </Modal>
   )
}
