import './SidebarMobileHeader.css'
import forvetLogo from '../../../assets/Sidebar/forvet_logo_white.svg'
import AppContext from '../../../contexts/AppContext';
import { useContext } from 'react';
import { Burger } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

export default function SidebarMobileHeader() {
   const { setIsNavbarOpen, isNavbarOpen, isCalendarMobile, setIsCalendarMobile } = useContext(AppContext);
   return (
      <header className='sidebar-mobile-header-container'>
         <Link to='/campus/dashboard'
         >
            < img className='sidebar-logo-image' src={forvetLogo} alt='logo de FORVET' />
         </Link>
         <div className='sidebar-mobile-icons-container'>
            <button className='sidebar-mobile--calendarButton' onClick={() => {
               setIsCalendarMobile(!isCalendarMobile)
               setIsNavbarOpen(false)
            }}>
               < IconCalendar color='#fff' className='sidebar-mobile--calendarIcon' />
            </button>
            <Burger color='#fff' size={40} opened={isNavbarOpen} onClick={() => {
               setIsNavbarOpen(!isNavbarOpen)
               setIsCalendarMobile(false)
            }} aria-label="Toggle navigation" />
         </div>
      </header>
   )
}
