
import './SidebarNavLink.css'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import AppContext from '../../../contexts/AppContext/AppContext'

export default function SidebarNavLink({ to = '/', text, icon, isButton, ...others }) {
   const { setIsNavbarOpen } = useContext(AppContext)
   if (isButton) {
      return (
         <button className='sidebar-navLink-container' to={to} {...others}>
            {icon && icon}
            <span className='sidebar-navLink-text'>{text}</span>
         </button>
      )
   }

   return (
      <NavLink className='sidebar-navLink-container' to={to} onClick={() => setIsNavbarOpen(false)} {...others}>
         {icon && icon}
         <span className='sidebar-navLink-text'>{text}</span>
      </NavLink>
   )
}
