import React from 'react';
import './PasswordChecker.css';
import {passwordStrength} from 'check-password-strength'

const PasswordChecker = ({password}) => {
    const strength = passwordStrength(password);
    const hasCondition = condition => strength.contains.includes(condition);
    const isLengthValid = strength.length >= 10;

    return (
        <div className="passwordChecker__wrapper">
            Consideraciones de contraseña:
            <div className="passwordChecker__container">
                <p>Longitud de 10 caracteres como mínimo:
                    {isLengthValid
                        ? <span style={{color: 'green'}}> ✓</span>
                        : <span style={{color: 'red'}}> ✗</span>}
                </p>
                <p>Contiene al menos 1 minúscula:
                    {hasCondition('lowercase')
                        ? <span style={{color: 'green'}}> ✓</span>
                        : <span style={{color: 'red'}}> ✗</span>}
                </p>
                <p>Contiene al menos 1 mayúscula:
                    {hasCondition('uppercase')
                        ? <span style={{color: 'green'}}> ✓</span>
                        : <span style={{color: 'red'}}> ✗</span>}
                </p>
                <p>Contiene al menos 1 número:
                    {hasCondition('number')
                        ? <span style={{color: 'green'}}> ✓</span>
                        : <span style={{color: 'red'}}> ✗</span>}
                </p>
                <p>Contiene al menos 1 símbolo:
                    {hasCondition('symbol')
                        ? <span style={{color: 'green'}}> ✓</span>
                        : <span style={{color: 'red'}}> ✗</span>}
                </p>
            </div>
        </div>
    );
};

export default PasswordChecker;
