import './CertificateOptions.css'

import { IconDownload } from '@tabler/icons-react';
import { Accordion, Button, Loader } from '@mantine/core'
import { CERTIFICATE_DOWNLOAD_OPTIONS } from '../../../utils/certificates-download-options';
import axios from 'axios';
import { useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import showNotification from '../../../utils/showNotification';
import { ERROR_HANDLER } from '../../../utils/error-handler';
import dogGif from '../../../assets/Certificate/dog_happy.gif'
import catGif from '../../../assets/Certificate/cat_happy.gif'
import AppContext from '../../../contexts/AppContext/AppContext';


export default function CertificateOptions({ acaUnit_id, setIsModal, state, setState, hasAllDownloadOptions }) {
   const { authToken } = useContext(AuthContext)
   const { currentUser } = useContext(AppContext)

   const handleDownload = async (data) => {
      if (!data) return

      setState(prevState => ({ ...prevState, isLoading: true }))

      try {
         const postData = { ...data, academicUnitId: acaUnit_id }

         const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/certificates/print`,
            postData,
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               },
               responseType: 'blob'
            })

         const blob = new Blob([response.data], { type: "application/pdf" });
         const url = window.URL.createObjectURL(blob);
         window.open(url);

         setState(prevState => ({
            ...prevState,
            success: {
               message: 'Tu certificado ha sido creado con éxito y se abrirá en una nueva pestaña',
               href: url
            }
         }))
         showNotification({
            color: "green",
            status: "success",
            title: "Tu certificado ha sido creado con éxito.",
            message: `Detalle: se abrirá en una nueva pestaña o hacé click en el enlace que aparece en pantalla.`
         })

      } catch (err) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al intentar crear tu certificado.",
            message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })
         setIsModal(false)
         console.error(err)

      } finally {
         setState(prevState => ({ ...prevState, isLoading: false }))
      }
   }

   const animalImages = {
      dog: dogGif,
      cat: catGif
   };

   const filteredOptions = [5] // Participant certificate
   const items = CERTIFICATE_DOWNLOAD_OPTIONS.filter(item => {
      if (hasAllDownloadOptions) {
         return !filteredOptions.includes(item.id); // If hasAllDownloadOptions is true, return all items
      } else {
         return filteredOptions.includes(item.id); // Else, filter the items
      }
   }).map(item => (
      <Accordion.Item key={item.text} value={item.text}>
         <Accordion.Control style={{ fontSize: 'var(--fs-body)' }} icon={item.icon}>{item.text}</Accordion.Control>
         <Accordion.Panel>
            <Button
               fw="normal"
               fullWidth
               mt="md"
               radius="md"
               onClick={() => handleDownload(item.value)}
               disabled={state?.isLoading}
               justifySelf='center'
            >
               {state?.isLoading
                  ? < Loader color={'#fff'} size={20} />
                  : <>
                     <IconDownload />
                     <p className="certificatesCard-buttons-textSize">Descargar</p>
                  </>
               }

            </Button>
         </Accordion.Panel>
      </Accordion.Item>
   ));

   return (
      <div className='CertificateOptions-container'>

         <div className='GetCertificateModal--title-container'>
            {/* < IconMoodSmileBeam size={150} color='#2C4B58' /> */}

            < img className='CertificateOptions-animal-gif' src={animalImages[currentUser.animalType] ?? catGif} alt={`${currentUser.animalType} feliz` ?? 'perro feliz'} />
            <h3 className='GetCertificateModal--title' >¡Felicidades!</h3>
            <h3 className='GetCertificateModal--title'>Estas son las opciones para descargar tu certificado</h3>
         </div>

         <Accordion >
            {items}
         </Accordion>

         {(!state?.isLoading && state?.success.message && !state?.error) &&
            <div className='CertificateOptions-success-container'>
               <p className='CertificateOptions-success--text'>{state?.success.message}</p>
               <a className='CertificateOptions-success--link' target='_blank' rel='noreferrer' href={state?.success.href}>O puedes hacer click aquí para abrirlo.</a>
            </div>
         }

         {state?.isLoading &&
            <div className='CertificateOptions-success-container'>
               <p className='CertificateOptions-success--text'>Estamos generando tu certificado...</p>
            </div>
         }

         <Button mx='16px' radius='md' disabled={state?.isLoading} onClick={() => setIsModal(false)}>Cerrar</Button>

         {(state?.error && !state?.isLoading) &&
            <div className='CertificateOptions-error-container'>
               <p className='CertificateOptions-error--text'>{state?.error}</p>
            </div>
         }


      </div>
   )
}
