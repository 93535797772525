import React from 'react';

const AppContext = React.createContext({
   isSidebarOpen: false,
   setIsSidebarOpen: null,
   isReady: false,
   currentUser: [],
   setCurrentUser: null,
   isNavbarOpen: false,
   setIsNavbarOpen: null,
   isCalendarCollapsed: true,
   setIsCalendarCollapsed: null,
   isCalendarMobile: false,
   setIsCalendarMobile: null,
   discussionThemes: undefined,
   setDiscussionThemes: null,
});

export default AppContext;