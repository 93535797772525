import './CertificateSurveyItem.css'

import { RATE_VALUES } from '../../../utils/rate-values'
import RateFaceButton from '../../CoursesClass/RateClassBanner/RateFaceButton/RateFaceButton'
import { IconX  } from '@tabler/icons-react'

export default function CertificateSurveyItem({ question, ...others }) {

   if (!question) return null
   return (
      <div className='CertificateSurveyItem-container'>
         <div className='CertificateSurvey-question'>
            <p className='CertificateSurvey-question--text'>{question}</p>
         </div>
         <form className='CertificateSurvey-rate-content' {...others} >
            < RateFaceButton icon={RATE_VALUES.good} label='muy buena' />
            < RateFaceButton icon={RATE_VALUES.regular} label='regular' />
            < RateFaceButton icon={RATE_VALUES.bad} label='mala' />
            < RateFaceButton icon={RATE_VALUES.notRated} label='no valorar' noImage>
               <div className='RateFaceButton--image RateFaceButton-notRated'>
                  <IconX strokeWidth={2} size={40} color='#2C4B58'   />
               </div>
            </RateFaceButton>

         </form>

      </div>
   )
}
