import { Button, Modal } from '@mantine/core'
import './OpportunityModal.css'

export default function OpportunityModal({ isModal, setIsModal, selectedOportunity }) {

   return (
      <Modal opened={isModal} onClose={() => setIsModal(false)} withCloseButton={false} size="768px">
         <div className='OpportunityModal-container'>

            <header className='OpportunityModal-header-container'>

               <figure className='OpportunityModal-banner-container'>
                  <img className='OpportunityModal--bannerImg' src={selectedOportunity.bannerImageUrl} alt='banner' />
               </figure>

               <figure className='OpportunityModal-logo-container'>
                  < img className='OpportunityModal--logoImg' src={selectedOportunity.logoUrl} alt='logo' />
               </figure>
            </header>

            <div className='OpportunityModal-text-container'>
               <h4 className='OpportunityModal--title'>{selectedOportunity.title}</h4>
               <p className='OpportunityModal-description'>{selectedOportunity.description}</p>
            </div>

            <footer className='OpportunityModal-footer-container'>
               
               <Button  onClick={() => setIsModal(false)} variant='outline' >Cerrar</Button>
               {selectedOportunity.redirectUrl &&
                  <Button color="var(--color-primary-dark)" component='a' href={selectedOportunity.redirectUrl} rel='noreferrer' target='_blank'>Ver más</Button>
               }
               
            </footer>
         </div>

      </Modal>
   )
}
