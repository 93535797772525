import { Link } from 'react-router-dom'
import './ForumCardContent.css'
import RichText from '../../../components/RichText/RichText';
import useRichTextEditor from '../../../hooks/useRichTextEditor';

export default function ForumCardContent({ title, text, tag, includeLink = true, discussionId, ForumPageContent }) {

   const { editor } = useRichTextEditor({content: text, editable: false})

   const content = (
      <div className='ForoCardContent-container'>

         {tag && (
            <div className='ForoCardContent-tag-container'>
               <span className='ForoCardContent-tag--text'>{tag}</span>
            </div>
         )}

         <div className='ForoCardContent-body'>
            {title && <h4 className='ForoCardContent--title'>{title}</h4>}
            {/* <p className={ForumPageContent ? 'ForoCardContent--text-forumPageContent-style' : 'ForoCardContent--text'}>{text}</p> */}
            < RichText editor={editor} editable={false} border={0} bgContent={ForumPageContent ? '#fff' : 'inherit'} />
         </div>

      </div>
   );

   return includeLink ? (
      <Link className='ForoCardContent-container'
         to={`/campus/foro-debate/${discussionId}`}
      >{content}</Link>
   ) : (
      content
   );
}