import './UserProfileCarousel.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useContext } from 'react'
import AppContext from '../../../contexts/AppContext'
import { USER_PROFILE_CAROUSEL_IMAGES } from '../../../utils/user-profile-carousel-images'
import { Image } from '@mantine/core'

export default function UserProfileCarousel() {
   const { currentUser } = useContext(AppContext)

   const petLiked = currentUser?.animalType ?? 'dog'
   const title = `Porque te gustan los ${petLiked === 'cat' ? 'gatos' : 'perros'}`
   return (
      <section className='UserProfileCarousel-container'>

         <h5 className='UserProfileCarousel--title'>{title}</h5>

         <Splide className='UserProfileCarousel--carousel' aria-label="Carrusel de imagenes de animales" options={{
            rewind: true,
            autoplay: true
         }} >
            {USER_PROFILE_CAROUSEL_IMAGES[petLiked]?.map(img => (
               <SplideSlide >
                  <Image
                     radius="var(--border-radius-sm)"
                     src={img}
                     h='100%'
                     fallbackSrc="https://placehold.co/600x400?text=Imagen"
                  />
               </SplideSlide>
            ))}

         </Splide>
      </section>
   )
}
