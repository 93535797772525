import { Skeleton } from '@mantine/core'
import './ForumSkeletonCard.css'

export default function ForumSkeletonCard({ variant = 1 }) {

   const renderSkeleton = () => {
      switch (variant) {
         case 1:
            return (
               <div className='ForumSkeletonCard-content'>

                  <Skeleton height={18} radius="xl" w='20%' />

                  <div className='ForumSkeletonCard--text'>
                  <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-2' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-3' />
                  </div>

                  <div className='ForumSkeletonCard--footer'>
                     <Skeleton height={16} radius="xl" width='100px' />
                  </div>

               </div>
            );
         case 2:
            return (
               <div className='ForumSkeletonCard-content'>

                  <Skeleton height={18} radius="xl" w='15%' />

                  <div className='ForumSkeletonCard--text'>
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-2' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-3' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-2' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                  </div>

                  <div className='ForumSkeletonCard--footer'>
                     <Skeleton height={16} radius="xl" width='100px' />
                  </div>

               </div>
            )

         default:
            return (
               <div className='ForumSkeletonCard-content'>

                  <Skeleton height={18} radius="xl" w='50%' />

                  <div className='ForumSkeletonCard--text'>
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-3' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-2' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-3' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-1' />
                     <Skeleton height={8} radius="xl" className='ForumSkeletonCard--width-3' />
                  </div>

                  <div className='ForumSkeletonCard--footer'>
                     <Skeleton height={16} radius="xl" width='100px' />
                  </div>

               </div>
            )
      }
   }

   return (
      <article className='ForumSkeletonCard-container'>

         <div className='ForumSkeletonCard-header'>
            <Skeleton height={50} circle />
            <Skeleton height={18} radius="xl" w='100px' />
         </div>

         {renderSkeleton()}

      </article>
   )
}
