import {Link} from "react-router-dom";
import "./SponsorCard.css";
import {Image} from "@mantine/core";
import ConfettiParticles from "../../../../../components/ConfettiParticles/ConfettiParticles";

const SponsorCard = (props) => {
    const {title, link, image} = props;

    // TODO: This was a fix for a temporal "chiche"
    if (link === "CONFETTI") {
        return (
            <ConfettiParticles>
                <div className="sponsorCard-container-div">
                    <Image className="sponsorCard-image-custom" src={image}/>
                    <div className="sponsorCard-content-container">
                        <h2 className="sponsorCard-title-for-sponsor">{title}</h2>
                    </div>
                </div>
            </ConfettiParticles>
        )
    }
    if (link) {
        return (
            <Link className="sponsorCard-link-navigation" to={link} target="_blank">
                <div className="sponsorCard-container-div">
                    <Image className="sponsorCard-image-custom" src={image}/>
                    <div className="sponsorCard-content-container">
                        <h2 className="sponsorCard-title-for-sponsor">{title}</h2>
                    </div>
                </div>
            </Link>
        );
    } else {
        return (
            <div className="sponsorCard-container-div">
                <Image className="sponsorCard-image-custom" src={image}/>
                <div className="sponsorCard-content-container">
                    <h2 className="sponsorCard-title-for-sponsor">{title}</h2>
                </div>
            </div>
        );
    }
};

export default SponsorCard;
