import {useContext, useEffect, useState} from "react";
import DecorativeEmptyCircle from "../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle";
import "./ActiveCourseCard.css";
import {Progress, Button} from "@mantine/core";
import axios from "axios";
import {useNavigate} from "react-router";
import ActiveCourseCardSkeleton from "./ActiveCourseCardSkeleton";
import AuthContext from "../../../contexts/AuthContext";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";


const ActiveCourseCard = () => {
    const [activeCourse, setactiveCourse] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState({isLoading: false, error: null})
    const {authToken} = useContext(AuthContext)

    const fetchActiveCourse = async () => {
        setLoading(prevState => ({...prevState, isLoading: true}))
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}campus/users/courses`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    }
                }
            );
            // Here we are getting & displaying only the last course from the user
            const currentCourse = response.data[response.data.length - 1];
            setactiveCourse({
                title: currentCourse.comercialInformation.name,
                id: currentCourse._id,
                days: currentCourse.comercialInformation.classDays,
                hours: currentCourse.comercialInformation.classHour,
                image: currentCourse.comercialInformation.image,
                percentage: currentCourse.percentage,
            });
        } catch (error) {
            setLoading(prevState => ({...prevState, error: 'Lo siento, ha ocurrido un error.'}))
            console.error("Error fetching activeCourse data:", error);
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al intentar cargar tus cursos.",
               message: `Detalle: ${ERROR_HANDLER[error?.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           })
        } finally {
            setLoading(prevState => ({...prevState, isLoading: false}))
        }
    };

    useEffect(() => {
        fetchActiveCourse();
    }, []);

    if (loading.isLoading || activeCourse === null) {
        return <ActiveCourseCardSkeleton/>;
    }

    const redirectToCourse = (courseId) => {
        navigate(`/campus/curso/${courseId}`)
    }

    if (activeCourse && !loading.isLoading && !loading.error) {
        return (
            <div key={activeCourse.id} className="activeCardCourse-column-div"
                 onClick={() => redirectToCourse(activeCourse.id)}>
                <DecorativeEmptyCircle
                    size={200}
                    opacity={0.1}
                    top={-100}
                    left={780}
                    color="#77BDE0"
                    borderWidth={32}
                />
                <DecorativeEmptyCircle
                    size={180}
                    opacity={0.1}
                    top={-60}
                    left={300}
                    color="#77BDE0"
                    borderWidth={32}
                />
                <DecorativeEmptyCircle
                    size={100}
                    opacity={0.1}
                    top={20}
                    left={610}
                    color="#77BDE0"
                    borderWidth={24}
                />
                <DecorativeEmptyCircle
                    size={80}
                    opacity={0.1}
                    top={-30}
                    left={110}
                    color="#77BDE0"
                    borderWidth={16}
                />
                <div className="activeCardCourse-father-div">
                    <div className="activeCardCourse-image-div">
                        <img className="activeCardCourse-img" src={activeCourse.image} alt={activeCourse.title}/>
                    </div>
                    <div className="activeCardCourse-info-div">
                        <p className="activeCardCourse-course-title">{activeCourse.title}</p>
                        <p className="activeCardCourse-course-description">{activeCourse.days}
                            <span>{activeCourse.hours}</span></p>
                    </div>
                </div>
                <div className="activeCardCourse-progressBar-div">
                    <p className="activeCardCourse-percentage-complete">
                        Curso completado en un {activeCourse.percentage}%
                    </p>
                    <Progress value={activeCourse.percentage} color="#DF3576"/>
                </div>
            </div>
        );
    }
    return (
        <div className="activeCourseCard-error-container">
            <p className="activeCourseCard-errorState">
                ¡Ups! Hubo un problema al cargar tu curso, intenta
                nuevamente o dirígete a:</p>
            <Button
                fw="normal"
                variant="outline"
                size="xs"
                color="var(--color-primary-light)"
                onClick={() => navigate("/campus/cursos")}
            >
                Mis cursos
            </Button>
        </div>
    )
};
export default ActiveCourseCard;
