import './AboutProfessorCard.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import AboutProfessorInfoContent from './AboutProfessorInfoContent/AboutProfessorInfoContent'
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

export default function AboutProfessorCard({ teachers }) {
   const isOneProfessor = teachers.length === 1

   return (
      <article className='ClassDetails-card-container'>
         <div className='ClassDetails-card--row'>
            <h5 className='ClassDetails-card-headline'>{isOneProfessor ? 'Sobre el profesor' : 'Sobre los profesores'}</h5>
         </div>

         {isOneProfessor
            ? teachers.map(professor => (
               < AboutProfessorInfoContent
                  key={professor._id}
                  about={professor.about}
                  name={professor.name}
                  lastname={professor.lastname}
                  profilePicture={professor.profilePicture} />
            ))
            : <Splide aria-label="Profesores del curso" options={{
               rewind: true,
               gap: '1rem',
               width: '100%',
               height: '100%'
            }}>
               {teachers.map(professor => (
                  <SplideSlide >
                     < AboutProfessorInfoContent
                        about={professor.about}
                        name={professor.name}
                        lastname={professor.lastname}
                        profilePicture={professor.profilePicture} />
                  </SplideSlide>
               ))}
            </Splide>
         }

      </article>
   )
}
