import DasboardRemindCard from "../../../../components/DashboardRemindCard/DasboardRemindCard";

const ReminderCards = ({ acaUnits }) => {
  return (
    <>
      {acaUnits.map((item, index) => (
        <DasboardRemindCard
          key={index}
          notificationType={item.type}
          notificationTitle={item.title}
          notificationMessage={item.notification}
          notificationDate={item.newDate}
        />
      ))}
    </>
  );
};

export default ReminderCards;
