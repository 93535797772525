import React from "react";
import "./SponsorProducts.css";
import SponsorCard from "./SponsorCard/SponsorCard";
import SponsorBanner from "./SponsorBanner/SponsorBanner";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {Skeleton} from "@mantine/core";


const SponsorProducts = ({notificateCarousel, bannerCarousel, isLoading}) => {
    return (
        <>
            <div className="sponsor-products-container">
                {isLoading && <>
                    < Skeleton h={150} w={'100%'}/>
                    < Skeleton h={150} w={'100%'}/>
                </>}

                {(Array.isArray(notificateCarousel) && notificateCarousel.length !== 0) &&
                <div className="sponsor-products-carousel">

                    {notificateCarousel.length === 1 ?
                        notificateCarousel?.map((product, index) => (
                            <SponsorCard
                                key={index}
                                image={product.img}
                                link={product.link}
                                title={product.title}
                            />
                        ))
                        : <Splide
                            options={{
                                rewind: true,
                                width: '100%',
                                gap: "1rem",
                                fixedHeight: 180,
                            }}
                        >
                            {notificateCarousel?.map((product, index) => (
                                <SplideSlide key={index}>
                                    <SponsorCard
                                        image={product.img}
                                        link={product.link}
                                        title={product.title}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide>
                    }

                </div>
                }
                {(Array.isArray(bannerCarousel) && bannerCarousel.length !== 0) &&
                <div className="sponsor-products-banner">

                    {bannerCarousel.length === 1
                        ? bannerCarousel.map((item, index) => (
                            <SponsorBanner img={item.img} link={item.link} title={item.title} key={index}/>
                        ))
                        : <Splide
                            options={{
                                rewind: true,
                                width: '100%',
                                gap: "1rem",
                                fixedHeight: 180,
                            }}
                        >
                            {bannerCarousel.map((item, index) => (
                                <SplideSlide>
                                    <SponsorBanner img={item.img} link={item.link} title={item.title} key={index}/>
                                </SplideSlide>
                            ))}
                        </Splide>
                    }

                </div>
                }
            </div>
        </>
    );
};

export default SponsorProducts;
