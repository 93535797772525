import ExamScoreComponent from "../../../components/ExamScore/ExamScoreComponent";
import CourseClassHeaderNavigation from "../../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation";
import "./ExamScore.css";
import {useNavigate} from "react-router";

const ExamScore = ({title, percentage, approvalPercentage, setReviewMode, examId, recoveryExamDate}) => {
    const navigate = useNavigate();
    const handleCustomNavigate = () => {
        navigate(`/campus/antes-de-evaluar/agregar-el-nombre-del-curso/${examId}`)
    };
    return (
        <>
            <section className="examScore-HeaderNavigation">
                <CourseClassHeaderNavigation btnText="Volver al Inicio" onNavigate={handleCustomNavigate}/>
            </section>
            <section className="examScore-mainContent">
                <ExamScoreComponent
                    title={title}
                    percentage={percentage}
                    setReviewMode={setReviewMode}
                    approvalPercentage={approvalPercentage}
                    recoveryExamDate={recoveryExamDate}/>
            </section>
        </>
    );
};
export default ExamScore;
