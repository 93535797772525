import { Skeleton } from "@mantine/core";

const ActiveCourseCardSkeleton = () => {
  return (
    <Skeleton height={180} style={{ marginBottom: 16 }}>
      <div className="activeCardCourse-column-div">
        <div className="activeCardCourse-father-div">
          <div className="activeCardCourse-content-div">
            <img className="activeCardCourse-img" alt="" />
            <div className="activeCardCourse-info-div">
              <p className="activeCardCourse-course-title"></p>
              <p className="activeCardCourse-course-description">
                <span></span>
              </p>
            </div>
          </div>
        </div>
        <div className="activeCardCourse-progressBar-div">
          <p className="activeCardCourse-percentage-complete">
            Curso completado en un %
          </p>
          <Skeleton height={12} style={{ marginTop: 8 }} />
        </div>
      </div>
    </Skeleton>
  );
};

export default ActiveCourseCardSkeleton;