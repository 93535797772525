import { IconChevronDown, IconChevronUp, IconFaceIdError } from '@tabler/icons-react'
import CourseCardSkeleton from '../../../components/CourseCard/CourseCardSkeleton/CourseCardSkeleton'
import useOwnCourses from '../../../hooks/useOwnCourses'
import OwnCoursesGridGroup from '../OwnCoursesGridGroup/OwnCoursesGridGroup'
import './OwnCoursesList.css'
import { useIsMount } from '../../../hooks/useIsMount'
import { Button } from '@mantine/core'

export default function OwnCoursesList() {
   const isMount = useIsMount()
   const { state, changeFilters, filters, sortedCourses } = useOwnCourses(isMount)
   
   return (
      <section className='OwnCoursesList-container'>

         {state.error &&
            <div className='OwnCoursesList-error-container'>
               < IconFaceIdError size={150} color='var(--color-red)' />
               <p className='OwnCoursesList-error--text'>{state.error}</p>
            </div>
         }

         {state.isLoading && < CourseCardSkeleton withTitle />}

         {(!state.error && !state.isLoading && sortedCourses.length !== 0) &&

            <>
               <Button w={'max-content'} onClick={() => changeFilters(filters.order === 'asc' ? 'des' : 'asc')}>
                  {filters.order === 'asc' ? < IconChevronUp /> : < IconChevronDown />}
                  <span>Porcentaje de avance</span>
               </Button>
               < OwnCoursesGridGroup cardsToMap={sortedCourses} />
            </>
         }

         {/* sin resultados y sin errores */}
         {(!state.error && !state.isLoading && sortedCourses.length === 0) &&
            <p className='OwnCoursesList-noResults--text'>No hemos encontrado resultados</p>
         }

         {/* si se quiere filtrar por cursos finalizados */}
         {/* {(!state.error && !state.isLoading && filteredCourses.length !== 0) &&
            < OwnCoursesGridGroup title='Cursos finalizados' cardsToMap={filteredCourses} cardsWithTitle={false} bgCardColor='var(--color-secondary-light)' />
         } */}
      </section>
   )
}
