export const groupByMonth = (array) => {

   const grouped = {};

   array?.forEach((item) => {
      const date = new Date(item.initDate);
      const monthName = date.toLocaleString('es-ES', { month: 'long' });

      if (!grouped[monthName]) {
         grouped[monthName] = {
            month: monthName,
            events: [],
         };
      }

      grouped[monthName].events.push(item);
   });

   const result = Object.values(grouped);

   return result;
};