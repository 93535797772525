import { Button, Loader } from "@mantine/core";
import { IconChevronDown, IconGavel, IconMessage } from "@tabler/icons-react";
import { NO_AUTHORIZATION_CODE } from "../../../utils/forum-rules";

export default function ForumHeaderButtonsDesktop({handleToggleFilter, isDecrecent, getDiscussionThemes, setIsModal, state}) {
   return (
      <div className='ForoPageHeader-buttons-container'>

         <div className='ForoPageHeader-filterButton-container'>
            <Button color='var(--color-secondary)' onClick={handleToggleFilter} w={{ base: '100%', sm: 'max-content' }} >
               < IconChevronDown className='ForoPageHeader-button--icon' />
               <span className='ForoPageHeader-button--text'>
                  {isDecrecent ? 'Más recientes' : 'Más antiguos'}
               </span>
            </Button>
         </div>

         <div className='ForoPageHeader--buttonGroup'>

            <Button variant='outline' w={{ base: '100%', md: 'auto' }} color='var(--color-tertiary)' onClick={() => setIsModal(true)} >
               < IconGavel className='ForoPageHeader-button--icon' />
               <span className='ForoPageHeader-button--text'>
                  Reglas del foro
               </span>
            </Button>

            <Button
               disabled={state.isLoading || state.error.status === NO_AUTHORIZATION_CODE} 
               w={{ base: '100%', md: 'auto' }} color='var(--color-dark-primary)' onClick={() => getDiscussionThemes()} >
               < IconMessage className='ForoPageHeader-button--icon' />
               <span className='ForoPageHeader-button--text' >{state.isLoading ? < Loader size={20} color="var(--color-base-ligth)" /> : "Nueva discusión"}</span>
            </Button>
         </div>
      </div>
   )
}
