import {Routes, Route} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Callback from "../pages/Callback/Callback";
import CampusRoutes from "./CampusRoutes";
import ValidatedCertificate from "../pages/ValidatedCertificate/ValidatedCertificate";
import Login from "../pages/Login/Login";
import ValidateEmail from "../pages/ValidateEmail/ValidateEmail";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/callback" element={<ProtectedRoute component={Callback}/>}/>
            <Route exact path="/" element={<Login/>}/>
            <Route exact path="/certificaciones/validacion/:userId/:acaUnitId" element={<ValidatedCertificate/>}/>
            <Route exact path="/email-validacion" element={<ValidateEmail/>}/>
            <Route exact path="/campus/*" element={<ProtectedRoute component={CampusRoutes}/>}/>
        </Routes>
    );
};
export default AppRoutes;