import './PrevExamInfoItem.css'

export default function PrevExamInfoItem({icon, headline, text}) {
  return (
   <div className='PrevExamPage-infoCard--iconGroup'>
      {icon && icon}
      <h5>{headline}</h5>
      {text && <span>{text}</span>}
   </div>
  )
}
