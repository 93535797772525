export const RATE_VALUES = {
   good: "good",
   regular: "average",
   bad: "bad",
   notRated: "notRated",
}

export const RATE_VALUES_ARR = [
   RATE_VALUES.good,
   RATE_VALUES.regular,
   RATE_VALUES.bad,
   RATE_VALUES.notRated,
]