import React from 'react'
import DecorativeEmptyCircle from '../DecorativeEmptyCircle/DecorativeEmptyCircle'
import { Progress } from '@mantine/core'

export default function CourseCard({ id, image, title, days, hours, percentage, withCircles = true, bgCardColor = 'var(--color-primary-light)', ...others }) {
   return (
      <div key={id} className="activeCardCourse-column-div" {...others} >
         {withCircles &&
            <>
               <DecorativeEmptyCircle
                  size={200}
                  opacity={0.1}
                  top={-100}
                  left={780}
                  color="#77BDE0"
                  borderWidth={32}
               />
               <DecorativeEmptyCircle
                  size={180}
                  opacity={0.1}
                  top={-60}
                  left={300}
                  color="#77BDE0"
                  borderWidth={32}
               />
               <DecorativeEmptyCircle
                  size={100}
                  opacity={0.1}
                  top={20}
                  left={610}
                  color="#77BDE0"
                  borderWidth={24}
               />
               <DecorativeEmptyCircle
                  size={80}
                  opacity={0.1}
                  top={-30}
                  left={110}
                  color="#77BDE0"
                  borderWidth={16}
               />
            </>
         }
         <div className="activeCardCourse-father-div" style={{ backgroundColor: bgCardColor }}>
            <div className="activeCardCourse-content-div">
               <img className="activeCardCourse-img" src={image} alt={title} />
               <div className="activeCardCourse-info-div">
                  <p className="activeCardCourse-course-title">{title}</p>
                  <p className="activeCardCourse-course-description">{days} <span>{hours}</span></p>
               </div>
            </div>
         </div>

         <div className="activeCardCourse-progressBar-div">
            <p className="activeCardCourse-percentage-complete">
               Curso completado en un {percentage}%
            </p>
            <Progress value={percentage} color="#DF3576" />
         </div>
      </div>
   )
}
