import axios from "axios"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../contexts/AuthContext"
import showNotification from "../utils/showNotification"
import { ERROR_HANDLER } from "../utils/error-handler"

export default function useOpportunities() {
   const {authToken} = useContext(AuthContext)
   const [selectedOportunity, setSelectedOportunity] = useState(null)
   const [isModal, setIsModal] = useState(false)
   const [state, setState] = useState({isLoading: false, error: null})
   const [opportunities, setOpportunities] = useState([])

   const handleClickCard = (data) => {
      setIsModal(true)
      setSelectedOportunity(data)
   }

   useEffect(() => {
      const getOpportunities = async () => {
         setState(prevState => ({...prevState, isLoading: true}))
         const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/opportunities`
         try {
            const { data } = await axios.get(url, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })

            setOpportunities(data)
            
         } catch (err) {
            console.error(err)
            setState(prevState => ({
               ...prevState, error: 'Lo siento, no hemos podido cargar las oportunidades. Intenta nuevamente.'
            }))
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al cargar oportunidades.",
               message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           });
         } finally{
            setState(prevState => ({...prevState, isLoading: false}))
         }
      }

      getOpportunities()
   }, [authToken])

   const [bannerHighlight, ...restOfItems] = opportunities?.reduce((acc, item) => {
      if (item.BannerHighlight) {
         acc[0].push(item)
      } else {
         acc[1].push(item)
      }
      return acc
   }, [[], []])

   return {
      isModal,
      setIsModal,
      selectedOportunity, 
      setSelectedOportunity,
      handleClickCard,
      state,
      bannerHighlight,
      restOfItems: restOfItems.flat()
      
   }
}
