import './LoginHeader.css'
import logoForvet from '../../../assets/RegisterPage/logoForvet_dark.svg'
import { Button } from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react';

export default function LoginHeader() {
   const { loginWithRedirect } = useAuth0();

   return (
      <header className='LoginPage-header-container'>
         <figure className='LoginPage-header--group'>
            < img className='LoginPage-header--logo' src={logoForvet} alt='logo FORVET' />
         </figure>
         <div className='LoginPage-header--group'>
            <Button size="lg" radius='md' color='var(--color-primary)' onClick={() => loginWithRedirect()}>INGRESAR</Button>
         </div>
      </header>
   )
}
