import "./CalendarAside.css";
import { IconLayoutSidebarLeftExpand, IconX } from "@tabler/icons-react";
import DecorativeEmptyCircle from "../DecorativeEmptyCircle/DecorativeEmptyCircle";
import CalendarItem from "./CalendarItem/CalendarItem";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Skeleton } from "@mantine/core";
import { groupByMonth } from "../../utils/group-by-month";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import showNotification from "../../utils/showNotification";
import { ERROR_HANDLER } from "../../utils/error-handler";

export default function CalendarAside() {
   const { authToken } = useContext(AuthContext)
   const {
      isCalendarMobile,
      setIsCalendarMobile,
      setIsCalendarCollapsed,
      isCalendarCollapsed
   } = useContext(AppContext);

   const [state, setState] = useState({ isLoading: false, error: null })
   const [schedule, setSchedule] = useState(null)


   useEffect(() => {
      const getSchedule = async () => {
         setState(prevState => ({...prevState, isLoading: true}))
         try {
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/schedule`, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })
            setSchedule(data)
         } catch (error) {
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al cargar el calendario.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`
           })
         } finally{
            setState(prevState => ({...prevState, isLoading: false}))
         }
      }
      getSchedule()
   }, [authToken])

   const { width } = useWindowDimensions()
   const isTablet = width < 1000;

   useEffect(() => {
      if (isTablet) {
         setIsCalendarCollapsed(false);
      }
   }, [isTablet]);


   // const groupedByMonth = groupByMonth(SCHEDULE);
   // 👇 deberia usarse (puede romper?)
   const groupedByMonth = groupByMonth(schedule);

   if (state.error) return null;
   return (
      <>
         {!state.isLoading && <Skeleton />}
         {!state.isLoading && !state.error && schedule && (
            <aside
               className={`CalendarAside-container ${isCalendarCollapsed ? "calendar-collapsed" : ""
                  } ${isCalendarMobile ? "calendar-mobile--open" : ""}`}
            >
               <DecorativeEmptyCircle
                  size={180}
                  opacity={0.4}
                  top={-100}
                  right={-14}
                  color="#A6B3BB"
                  borderWidth={28}
               />

               {!isCalendarCollapsed && (
                  <DecorativeEmptyCircle
                     size={230}
                     opacity={0.2}
                     bottom={100}
                     right={-100}
                     color="#8BA7B2"
                     borderWidth={48}
                  />
               )}

               <DecorativeEmptyCircle
                  size={100}
                  opacity={0.2}
                  bottom={15}
                  left={75}
                  color="#8BA7B2"
                  borderWidth={16}
               />

               <DecorativeEmptyCircle
                  size={100}
                  opacity={0.4}
                  bottom={120}
                  left={-45}
                  color="#A6B3BB"
                  borderWidth={28}
               />

               <header className="CalendarAside-header-container">
                  <h3 className="CalendarAside-header--text">Calendario</h3>

                  {isTablet ? (
                     <button
                        className="sidebar-closeCalendar-button"
                        onClick={() => setIsCalendarMobile(false)}
                     >
                        <IconX
                           color="var(--color-dark-blue)"
                           className="sidebar-closeCalendar-button--icon"
                        />
                     </button>

                  ) : (
                     <IconLayoutSidebarLeftExpand
                        size={32}
                        color="var(--color-primary)"
                        className={`calendar-back-icon ${isCalendarCollapsed ? "collapse" : ""
                           }`}
                        onClick={() => {
                           setIsCalendarCollapsed(!isCalendarCollapsed);
                        }}
                     />
                  )}

               </header>

               {groupedByMonth && (
                  <div className="CalendarAside-content">
                     {groupedByMonth.map((item) => (
                        <CalendarItem
                           key={item._id}
                           month={item.month}
                           events={item.events}
                        />
                     ))}
                  </div>
               )}
            </aside>
         )}
      </>
   );
}
