import { Highlight } from "@mantine/core";
import Color from "@tiptap/extension-color";
import Placeholder from "@tiptap/extension-placeholder";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Heading from "@tiptap/extension-heading";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Paragraph from '@tiptap/extension-paragraph'
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

export default function NoteClassCard({ content }) {
   const cleanContent = DOMPurify.sanitize(content);


   /* const editor = useEditor({
      extensions: [
         StarterKit,
         Underline,
         Link,
         Superscript,
         Subscript,
         Highlight,
         TextStyle,
         Color,
         Placeholder.configure({
            placeholder: 'Añade aquí tus notas de esta clase',
         }),
         TextAlign.configure({ types: ["heading", "paragraph"] }),
      ],
     content: cleanContent,
     editable: false,
   }); */

   const editor = useEditor({
      extensions: [
         StarterKit,
         Underline,
         Link,
         Superscript,
         Subscript,
         Highlight,
         TextStyle,
         Color,
         Placeholder.configure({
            placeholder: 'Añade aquí tus notas de esta clase',
         }),
         TextAlign.configure({ types: ["heading", "paragraph"] }),
         Heading.configure({
            levels: [1, 2, 3, 4],
            HTMLAttributes: {
               class: 'RichTextEditor--heading'
            }
         }),
         Paragraph.configure({
            HTMLAttributes: {
               class: 'RichTextEditor--paragraph'
            }
         })

      ],
      content: cleanContent,
      editable: false,
   });

   return (
      <div className="classNotes-savedNotes-father-div">
         <EditorContent className="NoteClassCard--content" editor={editor} />
      </div>
   );
}