import './ClassDetailsCards.css'
import DownloadClassInfoCard from './DownloadClassInfoCard/DownloadClassInfoCard';
import AboutProfessorCard from './AboutProfessorCard/AboutProfessorCard';
import AboutClassInfoCard from './AboutClassInfoCard/AboutClassInfoCard';
export default function ClassDetailsCards({teachers, date}) {

   return (
      <section className='ClassDetailsCards-container'>
         {date && < AboutClassInfoCard date={date} />}

         < DownloadClassInfoCard/>

         {teachers && < AboutProfessorCard teachers={teachers} />}

      </section>
   )
}
