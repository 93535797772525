import React, {useEffect, useState, useMemo, useContext} from "react";
import AppContext from "./AppContext.js";
import Axios from "axios";
import AuthContext from "../AuthContext";
import showNotification from "../../utils/showNotification";
import {ERROR_HANDLER} from "../../utils/error-handler.js";
import {useIsMount} from "../../hooks/useIsMount";

const AppContextProvider = ({children}) => {
    const {authToken} = useContext(AuthContext);
    const isMount = useIsMount();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false)
    const [isCalendarCollapsed, setIsCalendarCollapsed] = useState(false);
    const [isCalendarMobile, setIsCalendarMobile] = useState(false);

    const [currentUser, setCurrentUser] = useState([]);

    const [discussionThemes, setDiscussionThemes] = useState(undefined)

    useEffect(() => {
        if (!isMount) return
        const init = async () => {
            await Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/login`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                }
            })
                .then(({data}) => {
                    setCurrentUser({
                        ...data.user,
                        birthDate: new Date(data.user.birthDate)
                    })
                })
                .catch((err) => {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Ha ocurrido un problema al cargar tus datos.",
                        message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                        autoClose: 12000
                    })
                })
            setIsReady(true);
        };

        init();
    }, []);

    const context = useMemo(
        () => ({
            isSidebarOpen,
            setIsSidebarOpen,
            isReady,
            isNavbarOpen,
            setIsNavbarOpen,
            isCalendarCollapsed,
            setIsCalendarCollapsed,
            isCalendarMobile,
            setIsCalendarMobile,
            currentUser,
            setCurrentUser,
            discussionThemes,
            setDiscussionThemes
        }),
        [
            isSidebarOpen,
            setIsSidebarOpen,
            isReady,
            isNavbarOpen,
            setIsNavbarOpen,
            isCalendarCollapsed,
            setIsCalendarCollapsed,
            isCalendarMobile,
            setIsCalendarMobile,
            currentUser,
            setCurrentUser,
            discussionThemes,
            setDiscussionThemes
        ]
    );
    return (
        <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
};
export default AppContextProvider;
