import './SidebarNav.css'
import SidebarNavLink from '../SidebarNavLink/SidebarNavLink';
import { IconHome, IconSchool, IconCertificate, IconHelpOctagon, IconMessage, IconTargetArrow, IconUser } from '@tabler/icons-react';

const SIDEBAR_NAVLINKS = [
   {
      text: 'Home',
      icon: < IconHome className='sidebar-navLink--icon' size={32} />,
      to: '/campus/dashboard'
   },
   {
      text: 'Mis cursos',
      icon: < IconSchool className='sidebar-navLink--icon' size={32} />,
      to: '/campus/mis-cursos'
   },
   {
      text: 'Mis Certificados',
      icon: < IconCertificate className='sidebar-navLink--icon' size={32} />,
      to: '/campus/certificaciones'
   },
   {
      text: 'Foro',
      icon: < IconMessage className='sidebar-navLink--icon' size={32} />,
      to: '/campus/foro'
   },
   {
      text: 'Oportunidades',
      icon: < IconTargetArrow className='sidebar-navLink--icon' size={32} />,
      to: '/campus/oportunidades'
   },
   {
      text: 'Mi cuenta',
      icon: < IconUser className='sidebar-navLink--icon' size={32} />,
      to: '/campus/perfil'
   },
   {
      text: 'Ayuda',
      icon: < IconHelpOctagon className='sidebar-navLink--icon' size={32} />,
      to: '/campus/ayuda'
   },
]

export default function SidebarNav() {
   return (
      <nav className='sidebar-nav-container'>
         {SIDEBAR_NAVLINKS.map(item => (
            < SidebarNavLink key={item.text} text={item.text} icon={item.icon} to={item.to} />
         ))}
      </nav>
   )
}
