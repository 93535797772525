import axios from "axios"
import { useEffect, useState } from "react"
import { browserName } from 'react-device-detect';

export default function useLoginPage() {
   const [acaUnits, setAcaUnits] = useState(null)
   const [avals, setAvals] = useState([])
   const [isNotBrowserRecommended, setIsNotBrowserRecommended] = useState(null)
   const [state, setState] = useState({ isLoading: false, error: null })


   // detectar navegador
   useEffect(() => {
      const LOCAL_STORAGE_ITEM_KEY = 'FVW-recommended-browser'
      const NOT_RECOMMENDED_BROWSER = ['Brave','Safari','Firefox']
      
      const isInLocalStorage = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ITEM_KEY))
      
      if(isInLocalStorage) return
      
      if(!NOT_RECOMMENDED_BROWSER.includes(browserName)) return

      setIsNotBrowserRecommended(browserName)
      window.localStorage.setItem(LOCAL_STORAGE_ITEM_KEY, JSON.stringify(browserName))
   }, [])

   // fetch de datos
   useEffect(() => {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}info/academicUnits`

      const getAcaUnits = async () => {
         setState(prevState => ({ ...prevState, isLoading: true }))
         try {
            const { data } = await axios.get(url)
            setAcaUnits(data)
         } catch (err) {
            console.error(err)
            //mantine
            setState(prevState => ({ ...prevState, error: 'Ha ocurrido un problema.' }))
         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      }

      const getAvals = async () => {
         const url = `${process.env.REACT_APP_BACKEND_SERVER}info/avals`
         setState(prevState => ({ ...prevState, isLoading: true }))
         try {
            const { data } = await axios.get(url)
            setAvals(data)
         } catch (err) {
            console.error(err)
            //mantine
            setState(prevState => ({ ...prevState, error: 'Ha ocurrido un problema.' }))
         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      }

      getAvals()
      getAcaUnits()
   }, [])

   return {
      acaUnits,
      state,
      avals,
      isNotBrowserRecommended
   }
}
