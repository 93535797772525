import { useState } from "react";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import './TermsAndConditions.css';

const TermsAndConditions = ({checked, setChecked}) => {
  const [modalOpened, setModalOpened] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const openModal = () => {
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  return (
    <div>
      <label>
        <input
          className="termsAndConditions-input-checkbox"
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
      </label>
      <span className="termsAndConditions-legend-checkbox">Acepto los <span className="termsAndConditions-modal-opener" onClick={openModal}>términos y condiciones</span> de FORVET Campus.</span>
      <TermsAndConditionsModal isOpen={modalOpened} onClose={closeModal} />
    </div>
  );
};

export default TermsAndConditions;
