import React, {useState} from 'react';
import './MainLayout.css';
import Sidebar from "../../features/Sidebar/Sidebar/Sidebar";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SidebarMobileHeader from '../../features/Sidebar/SidebarMobileHeader/SidebarMobileHeader';

function MainLayout({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   const { width } = useWindowDimensions();
   const isTablet = width < 1000;

    return (
      <div className={`mainLayout__container ${isSidebarOpen ? " mainLayout__container--collapsed" : ""}`}>
         {/*<Header />*/}
         {(isTablet) && <SidebarMobileHeader />}
         <aside className="mainLayout__aside">
            <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
         </aside>
         <main className="mainLayout__main">
            {children}
         </main>
         {/*<FooterContainer/>*/}
      </div>
   );
}

export default MainLayout;