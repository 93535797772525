import './ForumCardHeader.css'
import { Avatar } from '@mantine/core'
import { IconMessage, IconUser } from '@tabler/icons-react'

export default function ForumCardHeader({ profilePicture, username = 'Anónimo', commentAmount = '0', hideCommentAmount = false }) {
   const showCommentAmount = !hideCommentAmount;

   return (
      <header className='ForoCard-header'>
         <div className='ForoCard-header--userInfo'>

            {profilePicture
               ? < Avatar h={50} w={50} src={profilePicture} alt={`foto de perfil del usuario ${username}`} />
               : < IconUser size={50} color='var(--color-text-primary)' />
            }
            <h5 className='ForoCard--username'>{username}</h5>
         </div>
         {showCommentAmount && (
            <div className='ForoCard-header--commentInfo'>
               <div className='ForoCard-header-commentInfo--container'>
                  < IconMessage size={16} color='var(--color-text-primary)' />
                  <span className='ForoCard-header-commentInfo--amount'>{commentAmount}</span>
               </div>
            </div>
         )}
      </header>
   )
}
