import { IconAt, IconBrandWhatsapp, IconSun } from "@tabler/icons-react";
import ContactCardIcon from "../components/Help/ContactCardIcon";
import { emailTo } from "../utils/emailTo";
import { wappTo } from "../utils/wappTo";

const useContactCard = () => {
  const SUBJECT = "Consulta desde el campus";
  const MESSAGE =
    "¡Hola, buenos días! Me contacto desde el campus virtual para consultar por...";

  const CONTACT_DATA = [
    {
      title: "Email",
      description: ["administracion@forvet.org"],
      icon: IconAt,
      onClick: (to) => emailTo(to, SUBJECT),
    },
    {
      title: "Telefono",
      description: ["+54 9 351 208 6545", "+54 9 351 857 1139"],
      icon: IconBrandWhatsapp,
      onClick: (to) => wappTo(to.replace(/\s/g, ""), MESSAGE),
    },
    {
      title: "Horario de atención",
      info: "Huso horario: Argentina",
      description: ["8 a.m. – 8 p.m."],
      icon: IconSun,
    },
  ];

  const generateContactItems = () => {
    return CONTACT_DATA.map((item, index) => (
      <ContactCardIcon key={index} {...item} />
    ));
  };

  return { generateContactItems };
};

export default useContactCard;
