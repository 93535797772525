import { IconFolderDown } from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";

export default function DownloadClassInfoCard() {
  return (
    <article className="ClassDetails-card-container">
      <div className="ClassDetails-card--row">
        <IconFolderDown size={50} color="var(--color-red--light)" />
      </div>
      <div className="ClassDetails-card--row">
        <h5 className="ClassDetails-card-headline">
          Descargá el material de esta clase
        </h5>
      </div>
      <div className="ClassDetails-card--row">
        <p className="ClassDetails-card-text">
          Aquí hallarás todos los recursos esenciales para llevar a cabo las
          prácticas en clase, junto con materiales adicionales que te serán
          útiles
        </p>
      </div>

      <Button m="auto" w="90%" component={Link} to={"material"}>
        Ver el material de clase
      </Button>
    </article>
  );
}
