import './SidebarHeader.css'
import forvetLogo from '../../../assets/Sidebar/forvet_logo_white.svg'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import AppContext from '../../../contexts/AppContext'
import { USER_DEFAULT_VALUES } from '../../../utils/user-default-values'

export default function SidebarHeader() {
   const { currentUser, setIsNavbarOpen } = useContext(AppContext)
   return (
      <div className='sidebar-header-container'>

         <figure className='sidebar-logo-container'>
            < img className='sidebar-logo-image' src={forvetLogo} alt='logo de FORVET' />
         </figure>

         <div className='sidebar-user-info-container'>
            <Link className='sidebar-avatar-container' to='/campus/perfil' onClick={() => setIsNavbarOpen(false)}>
               < img className='sidebar-avatar-image' src={ currentUser?.profilePicture ?? USER_DEFAULT_VALUES.profilePicture} alt='foto de perfil del usuario' />
            </Link> 

            <h3 className='sidebar-user-name'>{currentUser.name}</h3>

         </div>
         
      </div>
   )
}
