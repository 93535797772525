import React, { useContext, useEffect, useState } from "react";
import "./ReminderAndSponsors.css";
import ReminderCards from "./ReminderCards/ReminderCards";
import SponsorProducts from "./SponsorProducts/SponsorProducts";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";

const ReminderAndSponsors = () => {
   const [data, setData] = useState([])
   const [state, setState] = useState({isLoading: true, error: null})
   const { authToken } = useContext(AuthContext);


   useEffect(() => {

      const fetchReminderAndSponsors = async () => {
         setState(prevState => ({...prevState, isLoading: true}))
         try {
            const { data } = await axios.get(
               `${process.env.REACT_APP_BACKEND_SERVER}campus/news`,
               {
                  headers: {
                     "Content-Type": "application/json",
                     Authorization: "Bearer " + authToken,
                  },
               }
            );

            setData(data)

         } catch (error) {
            console.error("Error fetching activeCourse data:", error);
            setState(prevState => ({...prevState, error: true}))
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al cargar los patrocinadores.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           })
         } finally{
            setState(prevState => ({...prevState, isLoading: false}))
         }
      };

      fetchReminderAndSponsors();
   }, [authToken]);

   // setCarouselNotifications(data.nationality);
   // setBannerNotifications(data.general);
   // setAcaUnits(data.acaUnits);

   const acaUnits = data?.acaUnits;
   const carouselNotifications = data?.nationality;
   const bannerNotifications = data?.general;

   if(state.error) return null

   return (
      <>
         <h3 className="reminderAndSponsor-header">Novedades y Recordatorios</h3>
         {carouselNotifications?.length === 0 && bannerNotifications?.length === 0 && acaUnits?.length === 0 &&
            <p className="reminderAndSponsor-noText">No hay nada nuevo para mostrar aquí.</p>
         }
         <SponsorProducts notificateCarousel={carouselNotifications} bannerCarousel={bannerNotifications} isLoading={state.isLoading} />
         {acaUnits && <ReminderCards acaUnits={acaUnits} />}
      </>
   );
};

export default ReminderAndSponsors;
