import './OpportunitiesCardContainer.css'
import OpportunitiesCard from '../OpportunitiesCard/OpportunitiesCard'

export default function OpportunitiesCardContainer({handleClickCard, itemsToMap}) {
   return (
      <section className='OpportunitiesCardContainer-container'>
         <h3 className='OpportunitiesCardContainer-header'>Más oportunidades</h3>

         <div className='OpportunitiesCardContainer-grid-container'>

            {itemsToMap.map(item => (
                < OpportunitiesCard title={item.title} description={item.description} handleClickCard={handleClickCard} bannerImageUrl={item.bannerImageUrl} logoUrl={item.logoUrl} redirectUrl={item.redirectUrl} />
            ))}

            {/* < OpportunitiesCard title='otro titulo' description='otra descripcion un poco mas larga para ver como se comporta el copmponenteotra descripcion un poco mas larga para ver como se comporta el copmponente' handleClickCard={handleClickCard} bannerURL='https://img.freepik.com/fotos-premium/vista-lateral-pareja-aventurera-vivaqueando_23-2150562891.jpg?w=1380' logoURL='https://img.freepik.com/vector-gratis/vector-diseno-logotipo-tienda-bicicletas_53876-40626.jpg?size=626&ext=jpg' /> */}
         </div>
      </section>
   )
}