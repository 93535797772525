import './RateClassBanner.css'
import { useContext, useState } from 'react'
import RateFaceButton from './RateFaceButton/RateFaceButton'
import { Button, Loader } from '@mantine/core'
import axios from 'axios'
import AppContext from '../../../contexts/AppContext/AppContext'
import RateNotificationBanner from './RateNotificationBanner/RateNotificationBanner'
import DecorativeEmptyCircle from '../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle'
import { RATE_VALUES } from '../../../utils/rate-values'
import AuthContext from "../../../contexts/AuthContext";
import showNotification from '../../../utils/showNotification'
import { ERROR_HANDLER } from '../../../utils/error-handler'

export default function RateClassBanner({ lessonId }) {
   const { currentUser } = useContext(AppContext)
   const { authToken } = useContext(AuthContext)
   const [state, setState] = useState({ isLoading: false, isSuccess: false })
   const [currentRate, setCurrentRate] = useState(null)


   const handleRate = async () => {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/class/${lessonId}`

      const data = {
         user: currentUser._id,
         valoration: currentRate
      }
      if (!currentRate) return showNotification({
         color: "red",
         status: "error",
         title: "Ha ocurrido un problema al valorar la clase.",
         message: `Detalle: debes seleccionar un valor.`
      })

      setState(prevState => ({ ...prevState, isLoading: true }))

      try {
         await axios.put(url, data, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": "Bearer " + authToken,
            }
         })
         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Tu valoración ha sido enviada con éxito.",
            message: `Gracias por tu aporte. Esto nos ayuda a mejorar.`
         })
         setState(prevState => ({ ...prevState, isSuccess: true }))

      } catch (error) {

         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al intentar validar la clase.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })

      } finally {
         setState(prevState => ({ ...prevState, isLoading: false }))
      }

   }

   return (
      <section className='RateClassBanner-container'>
         {state.isSuccess
            ? < RateNotificationBanner message='¡Hemos recibido tu valoración!' rate={currentRate} />
            : <>
               <div className='RateClassBanner-text-container'>
                  <p>¡No olvides valorar la clase!</p>
                  <p>¿Como fue tu experiencia con esta clase?</p>
               </div>
               <div className='RateClassBanner-rate-container'>

                  <form className='RateClassBanner-rate-content' onChange={(event) => setCurrentRate(event.target.value)}>
                     < RateFaceButton icon={RATE_VALUES.good} isChecked={currentRate === RATE_VALUES.good} />
                     < RateFaceButton icon={RATE_VALUES.regular} isChecked={currentRate === RATE_VALUES.regular} />
                     < RateFaceButton icon={RATE_VALUES.bad} isChecked={currentRate === RATE_VALUES.bad} />
                  </form>

                  <Button w={'100%'} disabled={state.isLoading} onClick={() => handleRate()}>{state.isLoading ? < Loader color={'#fff'} size={30} /> : 'Enviar'}</Button>
               </div>

            </>
         }


         < DecorativeEmptyCircle size={70} opacity={.15} top={60} right={180} color='var(--color-secondary)' borderWidth={12} hideInMobile />
         < DecorativeEmptyCircle size={150} opacity={.15} top={100} right={40} color='var(--color-secondary)' borderWidth={24} hideInMobile />
         < DecorativeEmptyCircle size={100} opacity={.15} top={50} left={40} color='var(--color-secondary)' borderWidth={16} hideInMobile />
         < DecorativeEmptyCircle size={200} opacity={.15} bottom={-100} left={100} color='var(--color-secondary)' borderWidth={32} hideInMobile />
      </section>
   )
}
