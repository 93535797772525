import moment from "moment"

// export const isValidDate = (dateString) => {
//    if (!dateString) return

//    const today = moment()

//    const yearsDiff = today.diff(moment(dateString), 'years');
//    const isLegalAge = yearsDiff >= 18
//    const dateObject = new Date(dateString)
//    return !isNaN(dateObject.getTime()) && isLegalAge
// }

export const isValidDate = (dateString) => {
   // Verificar si la fecha está presente
   if (!dateString) return "La fecha no puede estar vacía.";

   const dateObject = moment(dateString);
   
   // Verificar si la fecha es válida
   if (!dateObject.isValid()) return "La fecha ingresada no es válida.";

   // Calcular la diferencia de años
   const today = moment();
   const yearsDiff = today.diff(dateObject, 'years');

   // Verificar si es mayor de edad
   if (yearsDiff < 18) return "Debe ser mayor de 18 años.";
   // La fecha es válida y la persona es mayor de edad
   return null;
};