import "./CoursesHeader.css";

const CoursesHeader = ({title, image}) => {
    return (
        <>
            <div className="coursesHeader-father-div">
                <h2 className="coursesHeader-title">{title}</h2>
                <img src={image} className="coursesHeader-image" alt="course-image"/>
                {/*{!video && (*/}
                {/*    <iframe*/}
                {/*        className="coursesHeader-iframe"*/}
                {/*        width="100%"*/}
                {/*        height="315"*/}
                {/*        src={video}*/}
                {/*        title="YouTube video player"*/}
                {/*        frameBorder="0"*/}
                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                {/*        allowFullScreen*/}
                {/*    />*/}
                {/*)}*/}
            </div>
        </>
    );
};
export default CoursesHeader;
