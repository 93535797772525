import React from 'react';
import "./NoContentMessage.css";

function NoContentMessage({gif, title, message}) {
    return (
        <div className="noContentMessage__container">
            <div className="noContentMessage__wrapper">
                <img className='noContentMessage__img' src={gif} alt="Sin contenido" />
                <h2 className="noContentMessage__title">{title}</h2>
                <p className="noContentMessage__message">{message}</p>
            </div>
        </div>
    );
}

export default NoContentMessage;