import './ConfirmExamModal.css'
import { Button, Modal } from "@mantine/core";

export default function ConfirmExamModal({ isModal, setIsModal, handleClick }) {
   
   return (
      <Modal opened={isModal} onClose={setIsModal} classNames={{ content:"ConfirmExamModal-container", title: "ConfirmExamModal--title" }} title="¡Estás a punto de iniciar el examen!" withCloseButton={false} >
        <p>Una vez comience, no podrás cerrarla. <br/> Inicia cuando estés preparado/a.</p>
        
         <div className="ConfirmExamModal-button-container">
            <Button onClick={() => setIsModal(false)} variant="light">Volver</Button>
            <Button onClick={handleClick} color="secondaryColor">Comenzar</Button>
         </div>

      </Modal>
   )
}
