import React, {useEffect, useState} from 'react';
import './Timer.css';
import {IconClock, IconEye, IconEyeOff} from "@tabler/icons-react";
import showNotification from "../../../utils/showNotification";

const formatTime = (value) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

function Timer(props) {
    const {timeLimit, submitExam, questions} = props;
    const [displayTime, setDisplayTime] = useState(true);
    const [time, setTime] = useState(timeLimit);

    useEffect(() => {
        if (time !== null) {
            if (time === 10 * 60) {
                showNotification({
                    autoClose: 8000,
                    color: 'yellow',
                    status: 'warning',
                    title: '¡Atención!',
                    message: `Restan 10 minutos para finalizar el examen.`,
                })
            }
            if (time === 60) {
                showNotification({
                    autoClose: 8000,
                    color: 'yellow',
                    status: 'warning',
                    title: '¡Atención!',
                    message: `Cuando finalice el tiempo en 1 minuto, el examen se enviará automáticamente.`,
                })
            }

            if (time <= 0) {
                submitExam(questions);
                return;
            }

            const timer = setTimeout(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [time]);


    if (time === null) return null;
    return (
        <div className="examTimer__container">
            <IconClock size={24} className="examTimer__clock"/>

            <p className="examTimer__text--bold">Tiempo restante:</p>
            {
                displayTime
                    ? <p className="examTimer__text">{formatTime(time)}</p>
                    : <p className="examTimer__text">-- : -- : --</p>
            }

            {displayTime
                ? <IconEyeOff className="examTimer__eye" size={20} onClick={() => setDisplayTime(false)}/>
                : <IconEye className="examTimer__eye" size={20} onClick={() => setDisplayTime(true)}/>}

        </div>
    );
}

export default Timer;