import React, {useContext, useEffect, useState} from 'react';
import './Exams.css';
import Questionnaire from "../../features/Exam/Questionnaire/Questionnaire";
import axios from "axios";
import {useIsMount} from "../../hooks/useIsMount";
import {useParams} from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import ExamScore from "../../features/Exam/ExamScore/ExamScore";
import {Loader} from "@mantine/core";
import showNotification from '../../utils/showNotification';
import { ERROR_HANDLER } from '../../utils/error-handler';

function Exams() {
    const isMount = useIsMount()
    const [reviewMode, setReviewMode] = useState(false);
    const [examData, setExamData] = useState(null);
    const [examResult, setExamResult] = useState(null);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const {id: examId} = useParams()
    const {authToken} = useContext(AuthContext);

    // FUNCTIONS
    const handleSubmit = async (values) => {
        setLoadingUpload(true);
        try {
            const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/exams/${examId}/submit`

            const jsonData = {
                answers: values.map(_question => {
                    return {
                        id: _question.id,
                        value: parseInt(_question.userAnswer)
                    }
                })
            }

            const response = await axios.put(url, jsonData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authToken,
                }
            })

            setExamResult(response.data);
            //TODO: Show success message and redirect to exams page

        } catch (err) {
            console.error("Error submitting exam:", err);
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al enviar tu exámen.",
               message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           })
        } finally {
            setLoadingUpload(false);
        }
    }

    const getQuestions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}campus/exams/${examId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    }
                }
            );
            const refactoredQuestions = response.data.questions.map((_question) => {
                return {
                    id: _question._id,
                    title: _question.title,
                    questionText: _question.questionText,
                    userAnswer: null,
                    error: null,
                    answers: _question.responseOptions.map((answer) => {
                        return {
                            value: answer.value.toString(),
                            label: answer.label,
                            id: answer._id
                        }
                    }),
                    //On review mode, add trueAnswer
                    // trueAnswer: "1"
                }
            })
            const durationInSecs = response.data.duration * 60;
            let timeRemainingInSecs = null;
            if (response.data.timeRemainingInMilliseconds) {
                timeRemainingInSecs = Math.round(response.data.timeRemainingInMilliseconds / 1000);
            }
            setExamData({
                title: response.data.name,
                duration: durationInSecs,
                questions: refactoredQuestions,
                timeRemaining: timeRemainingInSecs,
            });
        } catch (error) {
            console.error("Error fetching course data:", error);
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al cargar las preguntas de tu exámen.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           })
        }
    }

    const getQuestionsData = () => {
        if (reviewMode) {
            // Here we add the trueAnswer to the question for review mode
            return examData.questions.map((_question) => {
                const questionResult = examResult.answers.find((answer) => answer.id === _question.id)
                return {
                    ..._question,
                    userAnswer: questionResult?.value?.toString() || "",
                    trueAnswer: questionResult.trueOption.toString()
                }
            })
        } else {
            return examData.questions;
        }
    }

    // COMPONENT RENDERING
    const renderComponent = () => {
        if (loadingUpload) {
            return <Loader/>;
        }
        if (reviewMode) {
            return (
                <Questionnaire
                    title={examData.title}
                    questions={getQuestionsData()}
                    timeLimit={null}
                    reviewMode={reviewMode}
                    setReviewMode={setReviewMode}
                    examId={examId}
                    handleSubmit={handleSubmit}
                />
            );
        } else if (examResult) {
            return (
                <ExamScore
                    title={examResult.examName}
                    percentage={examResult.resultPercentage}
                    approvalPercentage={examResult.approvalPercentage}
                    setReviewMode={setReviewMode}
                    examId={examId}
                    recoveryExamDate={examResult.recoveryDate}
                />
            );
        } else if (examData) {
            return (
                <Questionnaire
                    title={examData.title}
                    questions={getQuestionsData()}
                    timeLimit={examData.timeRemaining ?? examData.duration}
                    reviewMode={reviewMode}
                    setReviewMode={setReviewMode}
                    examId={examId}
                    handleSubmit={handleSubmit}
                />
            );
        } else {
            return <Loader/>;
        }
    };

    // EFFECTS
    useEffect(() => {
        if (isMount) {
            getQuestions();
        }
        const beforeUnloadHandler = (event) => event.preventDefault();
        window.addEventListener('beforeunload', beforeUnloadHandler);
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);

    return (
        <div className="exams__container">
            {renderComponent()}
        </div>
    );
}

export default Exams;