import React from 'react';
import { Card, Skeleton, Container, Grid } from '@mantine/core';

const ForumDiscussionSkeleton = () => {
  return (
    <Card style={{ height: '100%' }} shadow="sm">
      <Container style={{ height: '100%', width: '100%' }} size="xl">
        <Grid >
          <Skeleton style={{marginTop: '16px', marginBottom: '64px'}} height={60} />
            <Skeleton style={{marginTop: '16px'}} height={240} />
          
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 64 }}>
              <div style={{ marginLeft: 8 }}>
                <Skeleton width={100} />
              </div>
            </div>
            <Skeleton style={{ marginTop: '16px' }} height={100} />
            <Skeleton style={{ marginTop: '16px', marginBottom: '16px' }} height={100} />
        </Grid>
      </Container>
    </Card>
  );
};

export default ForumDiscussionSkeleton;
