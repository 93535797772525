import { useNavigate } from 'react-router-dom'
import CourseCard from '../../../components/CourseCard/CourseCard'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import './OwnCoursesGridGroup.css'
export default function OwnCoursesGridGroup({ cardsToMap, title, cardsWithTitle = true, ...others }) {

   const navigate = useNavigate()
   
   return (
      <section className='OwnCoursesList-grid--group'>

         {title && cardsToMap.length !== 0 &&
            <div className='OwnCoursesList-grid-heading'>
               <h4 className='OwnCoursesList-grid-heading--text'>{title}</h4>
            </div>
         }
         <div className='OwnCoursesList-grid-container'>
         <ResponsiveMasonry columnsCountBreakPoints={{ 768: 1, 1200: 2 }}>
         <Masonry gutter="30px">
            {cardsToMap && cardsToMap?.map(item => (
               <CourseCard
                  key={item._id}
                  id={item._id}
                  image={item.comercialInformation.image}
                  title={item.comercialInformation.name}
                  days={item.comercialInformation.classDays}
                  hours={item.comercialInformation.classHour}
                  percentage={item.percentage}
                  onClick={() => navigate(`/campus/curso/${item._id}`)}
                  withCircles={cardsWithTitle}
                  {...others}
               />
            ))}
            </Masonry>
            </ResponsiveMasonry>
         </div>
      </section>
   )
}
